export default [
  {
    path: "/manufactories/workstation",
    name: "workstation.index",
    component: () =>
        import ("@/view/pages/modules/manufactories/workstation/Index.vue"),
    meta: {"role":["workstation.list"]},
  },
  {
    path: "/manufactories/workstation/create",
    name: "workstation.create",
    component: () =>
        import ("@/view/pages/modules/manufactories/workstation/Form.vue"),
    meta: {"role":["workstation.create"]},
  },
  {
    path: "/manufactories/workstation/edit/:id",
    name: "workstation.edit",
    component: () =>
        import ("@/view/pages/modules/manufactories/workstation/Form.vue"),
    meta: {"role":["workstation.update"]},
  },
  {
    path: "/manufactories/quality-inspections",
    name: "quality-inspections.index",
    component: () =>
        import ("@/view/pages/modules/manufactories/qualityInspection/Index.vue"),
    meta: {"role":["quality_inspection.list"]},
  },
  {
    path: "/manufactories/quality-inspections/create",
    name: "quality-inspections.create",
    component: () =>
        import ("@/view/pages/modules/manufactories/qualityInspection/Form.vue"),
    meta: {"role":["quality_inspection.create"]},
  },
  {
    path: "/manufactories/quality-inspections/edit/:id",
    name: "quality-inspections.edit",
    component: () =>
        import ("@/view/pages/modules/manufactories/qualityInspection/Form.vue"),
    meta: {"role":["quality_inspection.update"]},
  },
  {
    path: "/manufactories/operation-management",
    name: "operation-management.index",
    component: () =>
        import ("@/view/pages/modules/manufactories/operationManagement/Index.vue"),
    meta: {"role":["operation.list"]},
  },
  {
    path: "/manufactories/operation-management/create",
    name: "operation-management.create",
    component: () =>
        import ("@/view/pages/modules/manufactories/operationManagement/Form.vue"),
    meta: {"role":["operation.create"]},
  },
  {
    path: "/manufactories/operation-management/edit/:id",
    name: "operation-management.edit",
    component: () =>
        import ("@/view/pages/modules/manufactories/operationManagement/Form.vue"),
    meta: {"role":["operation.update"]},
  },
  {
    path: "/manufactories/routing-management",
    name: "routing-management.index",
    component: () =>
        import ("@/view/pages/modules/manufactories/routingManagement/Index.vue"),
    meta: {"role":["routing_management.list"]},
  },
  {
    path: "/manufactories/routing-management/create",
    name: "routing-management.create",
    component: () =>
        import ("@/view/pages/modules/manufactories/routingManagement/Form.vue"),
    meta: {"role":["routing_management.create"]},
  },
  {
    path: "/manufactories/routing-management/edit/:id",
    name: "routing-management.edit",
    component: () =>
        import ("@/view/pages/modules/manufactories/routingManagement/Form.vue"),
    meta: {"role":["routing_management.update"]},
  },
  {
    path: "/manufactories/bill-of-material",
    name: "bill-of-material.index",
    component: () =>
        import ("@/view/pages/modules/manufactories/billOfMaterial/Index.vue"),
    meta: {"role":["bill_material.list"]},
  },
  {
    path: "/manufactories/bill-of-material/create",
    name: "bill-of-material.create",
    component: () =>
        import ("@/view/pages/modules/manufactories/billOfMaterial/Form.vue"),
    meta: {"role":["bill_material.create"]},
  },
  {
    path: "/manufactories/bill-of-material/edit/:id",
    name: "bill-of-material.edit",
    component: () =>
        import ("@/view/pages/modules/manufactories/billOfMaterial/Form.vue"),
    meta: {"role":["bill_material.update"]},
  },
  {
    path: "/manufactories/bill-of-material/edit/:id/:is_clone?",
    name: "bill-of-material.clone",
    component: () =>
        import ("@/view/pages/modules/manufactories/billOfMaterial/Form.vue"),
    meta: {"role":["bill_material.clone"]},
  },
  {
    path: "/manufactories/material-list",
    name: "material-list.index",
    component: () =>
        import ("@/view/pages/modules/manufactories/materialList/Index.vue"),
    meta: {"role":["material.list"]},
  },
  {
    path: "/manufactories/material-list/create",
    name: "material-list.create",
    component: () =>
        import ("@/view/pages/modules/manufactories/materialList/Form.vue"),
    meta: {"role":["material.create"]},
  },
  {
    path: "/manufactories/material-list/edit/:id",
    name: "material-list.edit",
    component: () =>
        import ("@/view/pages/modules/manufactories/materialList/Form.vue"),
    meta: {"role":["material.update"]},
  },
  {
    path: "/manufactories/work-order",
    name: "work-order.index",
    component: () =>
        import ("@/view/pages/modules/manufactories/workOrder/Index.vue"),
    meta: {"role":["work_order.list"]},
  },
  {
    path: "/manufactories/work-order/create/:bill_id?",
    name: "work-order.create",
    component: () =>
        import ("@/view/pages/modules/manufactories/workOrder/Form.vue"),
    meta: {"role":["work_order.create","bill_material.add_work_order"]},
  },
  {
    path: "/manufactories/work-order/edit/:id",
    name: "work-order.edit",
    component: () =>
        import ("@/view/pages/modules/manufactories/workOrder/Form.vue"),
    meta: {"role":["work_order.update"]},
  },
  {
    path: "/manufactories/work-order/operations/:id",
    name: "work-order.operations",
    component: () =>
        import ("@/view/pages/modules/manufactories/workOrder/Operations.vue"),
    meta: {"role":["work_order.operations"]},
  },
  {
    path: "/manufactories/work-order/edit/:id/:is_clone?",
    name: "work-order.clone",
    component: () =>
        import ("@/view/pages/modules/manufactories/workOrder/Form.vue"),
    meta: {"role":["work_order.clone"]},
  },
  {
    path: "/manufactories/job-card",
    name: "job-card.index",
    component: () =>
        import ("@/view/pages/modules/manufactories/jobCard/Index.vue"),
    meta: {"role":["job_card.list"]},
  },
  {
    path: "/manufactories/job-card/edit/:id",
    name: "job-card.edit",
    component: () =>
        import ("@/view/pages/modules/manufactories/jobCard/Form.vue"),
    meta: {"role":["job_card.update"]},
  },
];