export default [
  {
    path: "salestargetcommission/sales-commissions",
    name: "sales-commissions.index",
    component: () =>
        import ("@/view/pages/modules/salesTargetCommission/commissionRule/Index.vue"),
    meta: {"role":["sales_commission.list"]},
  },
  {
    path: "salestargetcommission/sales-commissions/create",
    name: "sales-commissions.create",
    component: () =>
        import ("@/view/pages/modules/salesTargetCommission/commissionRule/Form.vue"),
    meta: {"role":["sales_commission.create"]},
  },
  {
    path: "salestargetcommission/sales-commissions/edit/:id",
    name: "sales-commissions.edit",
    component: () =>
        import ("@/view/pages/modules/salesTargetCommission/commissionRule/Form.vue"),
    meta: {"role":["sales_commission.update"]},
  },
  {
    path: "salestargetcommission/sales-commissions/view/:id",
    name: "sales-commissions.view",
    component: () =>
        import ("@/view/pages/modules/salesTargetCommission/commissionRule/View.vue"),
    meta: {"role":["sales_commission.list"]},
  },
  {
    path: "salestargetcommission/sales-periods",
    name: "sales-periods.index",
    component: () =>
        import ("@/view/pages/modules/salesTargetCommission/salesPeriods/Index.vue"),
    meta: {"role":["sales_period.list"]},
  },
  {
    path: "salestargetcommission/sales-periods/create",
    name: "sales-periods.create",
    component: () =>
        import ("@/view/pages/modules/salesTargetCommission/salesPeriods/Form.vue"),
    meta: {"role":["sales_period.create"]},
  },
  {
    path: "salestargetcommission/sales-periods/edit/:id",
    name: "sales-periods.edit",
    component: () =>
        import ("@/view/pages/modules/salesTargetCommission/salesPeriods/Form.vue"),
    meta: {"role":["sales_period.update"]},
  },
  {
    path: "salestargetcommission/sales-periods/view/:id",
    name: "sales-periods.view",
    component: () =>
        import ("@/view/pages/modules/salesTargetCommission/salesPeriods/View.vue"),
    meta: {"role":["sales_period.list"]},
  },
  {
    path: "salestargetcommission/sales-commission-details",
    name: "sales-commission-details.index",
    component: () =>
        import ("@/view/pages/modules/salesTargetCommission/salesCommissionDetails/Index.vue"),
    meta: {"role":["sales_commission_details.list"]},
  },
  {
    path: "salestargetcommission/sales-commission-details/view/:id",
    name: "sales-commission-details.view",
    component: () =>
        import ("@/view/pages/modules/salesTargetCommission/salesCommissionDetails/View.vue"),
    meta: {"role":["sales_commission_details.list"]},
  },
];