import { render, staticRenderFns } from "./CustomUploadImage2.vue?vue&type=template&id=936a964a&scoped=true"
import script from "./CustomUploadImage2.vue?vue&type=script&lang=js"
export * from "./CustomUploadImage2.vue?vue&type=script&lang=js"
import style0 from "./CustomUploadImage2.vue?vue&type=style&index=0&id=936a964a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "936a964a",
  null
  
)

export default component.exports