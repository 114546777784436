export default [
  {
    path: "/company-configuration",
    name: "company-configuration.index",
    component: () =>
        import ("@/view/pages/modules/configuration/company/Form.vue"),
    meta: {"role":["companies.company_profile"]},
  },
  {
    path: "/user-settings",
    name: "user-settings.index",
    component: () =>
        import ("@/view/pages/modules/configuration/user/Form.vue"),
  },
];