export default [
  {
    path: "/finances/income_categories",
    name: "income_categories.index",
    component: () =>
        import ("@/view/pages/modules/finances/incomeCategories/Form.vue"),
    meta: {"role":["income_categories.list"]},
  },
  {
    path: "finances/treasury-checking",
    name: "treasury-checking.index",
    component: () =>
        import ("@/view/pages/modules/finances/treasuryChecking/Index.vue"),
    meta: {"role":["treasury_checking.list"]},
  },
  {
    path: "finances/treasury-checking/create",
    name: "treasury-checking.create",
    component: () =>
        import ("@/view/pages/modules/finances/treasuryChecking/Form.vue"),
    meta: {"role":["treasury_checking.create"]},
  },
  {
    path: "finances/treasury-checking/edit/:id?",
    name: "treasury-checking.edit",
    component: () =>
        import ("@/view/pages/modules/finances/treasuryChecking/Form.vue"),
    meta: {"role":["treasury_checking.update"]},
  },
  {
    path: "finances/treasury-checking-history",
    name: "treasury-checking-history.index",
    component: () =>
        import ("@/view/pages/modules/finances/treasuryCheckingHistory/Index.vue"),
    meta: {"role":["treasury_checking.treasury_checking_history"]},
  },
  {
    path: "finances/expenses_category",
    name: "expenses_category.index",
    component: () =>
        import ("@/view/pages/modules/finances/expencesCategory/Form.vue"),
    meta: {"role":["expenses_category.list"]},
  },
  {
    path: "finances/expenses",
    name: "expenses.index",
    component: () =>
        import ("@/view/pages/modules/finances/expenses/Index.vue"),
    meta: {"role":["expenses.list"]},
  },
  {
    path: "finances/expenses/create",
    name: "expenses.create",
    component: () =>
        import ("@/view/pages/modules/finances/expenses/Form.vue"),
    meta: {"role":["expenses.create"]},
  },
  {
    path: "finances/expenses/edit/:id",
    name: "expenses.edit",
    component: () =>
        import ("@/view/pages/modules/finances/expenses/Form.vue"),
    meta: {"role":["expenses.update"]},
  },
  {
    path: "finances/expenses/view/:id",
    name: "expenses.view",
    component: () =>
        import ("@/view/pages/modules/finances/expenses/View.vue"),
    meta: {"role":["expenses.list"]},
  },
  {
    path: "finances/incomes",
    name: "incomes.index",
    component: () =>
        import ("@/view/pages/modules/finances/incomes/Index.vue"),
    meta: {"role":["incomes.list"]},
  },
  {
    path: "finances/incomes/create",
    name: "incomes.create",
    component: () =>
        import ("@/view/pages/modules/finances/incomes/Form.vue"),
    meta: {"role":["incomes.create"]},
  },
  {
    path: "finances/incomes/view/:id",
    name: "incomes.view",
    component: () =>
        import ("@/view/pages/modules/finances/incomes/View.vue"),
    meta: {"role":["incomes.list"]},
  },
  {
    path: "finances/incomes/edit/:id",
    name: "incomes.edit",
    component: () =>
        import ("@/view/pages/modules/finances/incomes/Form.vue"),
    meta: {"role":["incomes.update"]},
  },
  {
    path: "finances/banks",
    name: "banks.index",
    component: () =>
        import ("@/view/pages/modules/finances/banks/Index.vue"),
    meta: {"role":["banks.list"]},
  },
  {
    path: "finances/banks/create",
    name: "banks.create",
    component: () =>
        import ("@/view/pages/modules/finances/banks/Form.vue"),
    meta: {"role":["banks.create"]},
  },
  {
    path: "finances/banks/edit/:id",
    name: "banks.edit",
    component: () =>
        import ("@/view/pages/modules/finances/banks/Form.vue"),
    meta: {"role":["banks.update"]},
  },
  {
    path: "finances/banks/view/:id",
    name: "banks.view",
    component: () =>
        import ("@/view/pages/modules/finances/banks/View.vue"),
    meta: {"role":["banks.list"]},
  },
  {
    path: "finances/treasuries",
    name: "treasuries.index",
    component: () =>
        import ("@/view/pages/modules/finances/treasuries/Index.vue"),
    meta: {"role":["treasuries.list"]},
  },
  {
    path: "finances/treasuries/create",
    name: "treasuries.create",
    component: () =>
        import ("@/view/pages/modules/finances/treasuries/Form.vue"),
    meta: {"role":["treasuries.create"]},
  },
  {
    path: "finances/treasuries/view/:id",
    name: "treasuries.view",
    component: () =>
        import ("@/view/pages/modules/finances/treasuries/View.vue"),
    meta: {"role":["treasuries.list"]},
  },
  {
    path: "finances/treasuries/edit/:id",
    name: "treasuries.edit",
    component: () =>
        import ("@/view/pages/modules/finances/treasuries/Form.vue"),
    meta: {"role":["treasuries.update"]},
  },
  {
    path: "finances/expenses-income-transaction",
    name: "expenses-income-transaction.index",
    component: () =>
        import ("@/view/pages/modules/finances/expensesIncomeTransaction/Index.vue"),
    meta: {"role":["expenses_incomes.list"]},
  },
  {
    path: "finances/expenses-income-transaction/create",
    name: "expenses-income-transaction.create",
    component: () =>
        import ("@/view/pages/modules/finances/expensesIncomeTransaction/Form.vue"),
    meta: {"role":["expenses_incomes.create"]},
  },
  {
    path: "finances/expenses-income-transaction/edit/:id",
    name: "expenses-income-transaction.edit",
    component: () =>
        import ("@/view/pages/modules/finances/expensesIncomeTransaction/Form.vue"),
    meta: {"role":["expenses_incomes.update"]},
  },
  {
    path: "finances/expenses-income-transaction/view/:id",
    name: "expenses.view",
    component: () =>
        import ("@/view/pages/modules/finances/expensesIncomeTransaction/View.vue"),
    meta: {"role":["expenses_incomes.list"]},
  },
  {
    path: "finances/expenses-income-transaction/doc-payment-details/:id",
    name: "doc-payment-details.view",
    component: () =>
        import ("@/view/pages/modules/finances/expensesIncomeTransaction/paymentDetails.vue"),
    meta: {"role":["expenses_incomes.payment_details"]},
  },
  {
    path: "finances/bank-checking",
    name: "bank-checking.index",
    component: () =>
        import ("@/view/pages/modules/finances/bankChecking/Index.vue"),
    meta: {"role":["bank_checking.list"]},
  },
  {
    path: "finances/bank-checking/create",
    name: "bank-checking.create",
    component: () =>
        import ("@/view/pages/modules/finances/bankChecking/Form.vue"),
    meta: {"role":["bank_checking.create"]},
  },
  {
    path: "finances/bank-checking/edit/:id?",
    name: "bank-checking.edit",
    component: () =>
        import ("@/view/pages/modules/finances/bankChecking/Form.vue"),
    meta: {"role":["bank_checking.update"]},
  },
  {
    path: "finances/bank-checking-history",
    name: "bank-checking-history.index",
    component: () =>
        import ("@/view/pages/modules/finances/bankCheckingHistory/Index.vue"),
    meta: {"role":["bank_checking.bank_checking_history"]},
  },
];