export default [
  {
    path: "points-credits/credit-types",
    name: "credit-types.index",
    component: () =>
        import ("@/view/pages/modules/pointsCredits/creditTypes/Index.vue"),
    meta: {"role":["credit_type.list"]},
  },
  {
    path: "points-credits/credit-types/create",
    name: "credit-types.create",
    component: () =>
        import ("@/view/pages/modules/pointsCredits/creditTypes/Form.vue"),
    meta: {"role":["credit_type.create"]},
  },
  {
    path: "points-credits/credit-types/edit/:id",
    name: "credit-types.edit",
    component: () =>
        import ("@/view/pages/modules/pointsCredits/creditTypes/Form.vue"),
    meta: {"role":["credit_type.update"]},
  },
  {
    path: "points-credits/credit-types/view/:id",
    name: "credit-types.view",
    component: () =>
        import ("@/view/pages/modules/pointsCredits/creditTypes/View.vue"),
    meta: {"role":["credit_type.list"]},
  },
  {
    path: "points-credits/credit-usages",
    name: "credit-usages.index",
    component: () =>
        import ("@/view/pages/modules/pointsCredits/creditUsages/Index.vue"),
    meta: {"role":["credit_usage.list"]},
  },
  {
    path: "points-credits/credit-usages/create",
    name: "credit-usages.create",
    component: () =>
        import ("@/view/pages/modules/pointsCredits/creditUsages/Form.vue"),
    meta: {"role":["credit_usage.create"]},
  },
  {
    path: "points-credits/credit-usages/edit/:id",
    name: "credit-usages.edit",
    component: () =>
        import ("@/view/pages/modules/pointsCredits/creditUsages/Form.vue"),
    meta: {"role":["credit_usage.update"]},
  },
  {
    path: "points-credits/credit-usages/view/:id",
    name: "credit-usages.view",
    component: () =>
        import ("@/view/pages/modules/pointsCredits/creditUsages/View.vue"),
    meta: {"role":["credit_usage.list"]},
  },
  {
    path: "points-credits/package-info",
    name: "package-info.index",
    component: () =>
        import ("@/view/pages/modules/pointsCredits/packageInfo/Index.vue"),
    meta: {"role":["package_info.list"]},
  },
  {
    path: "points-credits/package-info/create",
    name: "package-info.create",
    component: () =>
        import ("@/view/pages/modules/pointsCredits/packageInfo/Form.vue"),
    meta: {"role":["package_info.create"]},
  },
  {
    path: "points-credits/package-info/edit/:id",
    name: "package-info.edit",
    component: () =>
        import ("@/view/pages/modules/pointsCredits/packageInfo/Form.vue"),
    meta: {"role":["package_info.update"]},
  },
  {
    path: "points-credits/package-info/view/:id",
    name: "package-info.view",
    component: () =>
        import ("@/view/pages/modules/pointsCredits/packageInfo/View.vue"),
    meta: {"role":["package_info.list"]},
  },
];