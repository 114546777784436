export default [
  {
    path: "installment/installment",
    name: "installment.list",
    component: () =>
        import ("@/view/pages/modules/installment/installment/Index.vue"),
    meta: {"role":["installments.list"]},
  },
  {
    path: "installment/installment/create/:invoice_id",
    name: "installment.create",
    component: () =>
        import ("@/view/pages/modules/installment/installment/Form.vue"),
    meta: {"role":["installments.create","sales_invoices.installments"]},
  },
  {
    path: "installment/installment/edit/:id",
    name: "installment.edit",
    component: () =>
        import ("@/view/pages/modules/installment/installment/Form.vue"),
    meta: {"role":["installments.update"]},
  },
  {
    path: "installment/installment/view/:id",
    name: "installment.view",
    component: () =>
        import ("@/view/pages/modules/installment/installment/View.vue"),
    meta: {"role":["installments.list"]},
  },
  {
    path: "installment/installment/pay/:details_id/:installment_id",
    name: "installment.pay",
    component: () =>
        import ("@/view/pages/modules/installment/installment/Pay.vue"),
    meta: {"role":["installments.pay"]},
  },
  {
    path: "installment/payments",
    name: "installment-payments.list",
    component: () =>
        import ("@/view/pages/modules/installment/payments/Index.vue"),
    meta: {"role":["installment_payments.list"]},
  },
  {
    path: "installment/payments/view/:id",
    name: "installment-payments.view",
    component: () =>
        import ("@/view/pages/modules/installment/payments/View.vue"),
    meta: {"role":["installment_payments.list"]},
  },
];