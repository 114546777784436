export default [
  {
    path: "reports/daily-brief-report-invoices",
    name: "daily-brief-report-invoices.list",
    component: () =>
        import ("@/view/pages/modules/statistics/dailyBriefReportInvoices/Index.vue"),
    meta: {"role":["report_daily_brief_invoices.list"]},
  },
  {
    path: "reports/daily-brief-report-payments",
    name: "daily-brief-report-payments.list",
    component: () =>
        import ("@/view/pages/modules/statistics/dailyBriefReportPaymants/Index.vue"),
    meta: {"role":["report_stores.daily_brief_report_payments"]},
  },
];