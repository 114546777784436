export default [
  {
    path: "insuranceagents/insurance-agents",
    name: "insurance-agents.index",
    component: () =>
        import ("@/view/pages/modules/insuranceagents/insuranceAgents/Index.vue"),
    meta: {"role":["insurance_agents.list"]},
  },
  {
    path: "insuranceagents/insurance-agents/create",
    name: "insurance-agents.create",
    component: () =>
        import ("@/view/pages/modules/insuranceagents/insuranceAgents/Form.vue"),
    meta: {"role":["insurance_agents.create"]},
  },
  {
    path: "insuranceagents/insurance-agents/edit/:id",
    name: "insurance-agents.edit",
    component: () =>
        import ("@/view/pages/modules/insuranceagents/insuranceAgents/Form.vue"),
    meta: {"role":["insurance_agents.update"]},
  },
  {
    path: "insuranceagents/insurance-agents/view/:id",
    name: "insurance-agents.view",
    component: () =>
        import ("@/view/pages/modules/insuranceagents/insuranceAgents/View.vue"),
    meta: {"role":["insurance_agents.list"]},
  },
  {
    path: "insuranceagents/insurance-agents/classes/:id",
    name: "insurance-agents.classes",
    component: () =>
        import ("@/view/pages/modules/insuranceagents/insuranceAgents/Classes.vue"),
    meta: {"role":["insurance_agents.classes"]},
  },
];