export default [
    {
        redirect: "/dashboard",
        path: "/point-of-sales",
        component: () =>
            import ("@/view/layout/LayoutForPointOfSales"),
        children: [
            // ******************************* Begin point-of-sales **************************************************

            {
                path: "point-of-sales",
                name: "point-of-sales.index",
                component: () =>
                    import ("@/view/pages/modules/pos/pointOfSales/IndexTabs.vue"),
                meta: {role: ['point_sales.list']}
            },
            // {
            //     path: "point-of-sales",
            //     name: "point-of-sales.index",
            //     component: () =>
            //         import ("@/view/pages/modules/pos/pointOfSales/Index.vue"),
            //     // meta: { role: ['point_of_sales.list'] }
            // },

            // ******************************* End point-of-sales **************************************************

        ]
    },
    {
        redirect: "/dashboard",
        path: "/point-of-sales",
        component: () =>
            import ("@/view/layout/LayoutForPointOfSales"),
        children: [
            // ******************************* Begin point-of-sales **************************************************

            {
                path: "point-of-sales-laundry",
                name: "point-of-sales-laundry.index",
                component: () =>
                    import ("@/view/pages/modules/pos/pointOfSalesLaundry/IndexTabs.vue"),
                meta: {role: ['point_sales_laundry.list']}
            },
            // {
            //     path: "point-of-sales",
            //     name: "point-of-sales.index",
            //     component: () =>
            //         import ("@/view/pages/modules/pos/pointOfSales/Index.vue"),
            //     // meta: { role: ['point_of_sales.list'] }
            // },

            // ******************************* End point-of-sales **************************************************

        ]
    }
];