export default [
  {
    path: "purchases/suppliers",
    name: "suppliers.index",
    component: () =>
        import ("@/view/pages/modules/purchases/suppliers/Index.vue"),
    meta: {"role":["suppliers.list"]},
  },
  {
    path: "purchases/suppliers/create",
    name: "suppliers.create",
    component: () =>
        import ("@/view/pages/modules/purchases/suppliers/Form.vue"),
    meta: {"role":["suppliers.create"]},
  },
  {
    path: "purchases/suppliers/edit/:id",
    name: "suppliers.edit",
    component: () =>
        import ("@/view/pages/modules/purchases/suppliers/Form.vue"),
    meta: {"role":["suppliers.update"]},
  },
  {
    path: "purchases/suppliers/view/:id",
    name: "suppliers.view",
    component: () =>
        import ("@/view/pages/modules/purchases/suppliers/View.vue"),
    meta: {"role":["suppliers.list"]},
  },
  {
    path: "suppliers/export-import-suppliers",
    name: "export-import-suppliers.index",
    component: () =>
        import ("@/view/pages/modules/purchases/suppliers/importSuppliers/Index.vue"),
    meta: {"role":["suppliers.export_import_suppliers"]},
  },
  {
    path: "purchases/purchases-invoices",
    name: "purchases-invoices.index",
    component: () =>
        import ("@/view/pages/modules/purchases/purchaseInvoice/Index.vue"),
    meta: {"role":["purchases_invoices.list"]},
  },
  {
    path: "purchases/purchases-invoices/payment_details/:id",
    name: "purchases_invoices.payment_details",
    component: () =>
        import ("@/view/pages/modules/purchases/purchaseInvoice/PaymentDetails.vue"),
    meta: {"role":["purchases_invoices.payment_details"]},
  },
  {
    path: "purchases/purchases-invoices/create/:purchase_request_id?/:quotation_type?",
    name: "purchases-invoices.create",
    component: () =>
        import ("@/view/pages/modules/purchases/purchaseInvoice/Form.vue"),
    meta: {"role":["purchases_invoices.create","purchase_quotations.convert_to_purchase_invoice","purchase_orders.convert_to_purchase_invoice","purchases_requests.transfer_to_purchase_invoice"]},
  },
  {
    path: "purchases/purchases-invoices/edit/:id",
    name: "purchases-invoices.edit",
    component: () =>
        import ("@/view/pages/modules/purchases/purchaseInvoice/Form.vue"),
    meta: {"role":["purchases_invoices.update"]},
  },
  {
    path: "purchases/purchases-invoices/view/:id",
    name: "purchases-invoices.view",
    component: () =>
        import ("@/view/pages/modules/purchases/purchaseInvoice/View.vue"),
    meta: {"role":["purchases_invoices.list"]},
  },
  {
    path: "purchases/purchases-refunds",
    name: "purchases-refunds.index",
    component: () =>
        import ("@/view/pages/modules/purchases/purchaseRefund/Index.vue"),
    meta: {"role":["purchases_refunds.list"]},
  },
  {
    path: "purchases/purchases-refunds/payment-details/:id",
    name: "purchases_invoices.payment_details",
    component: () =>
        import ("@/view/pages/modules/purchases/purchaseRefund/PaymentDetails.vue"),
    meta: {"role":["purchases_refunds.payment_details"]},
  },
  {
    path: "purchases/purchases-refunds/create/:purchase_invoice_id?",
    name: "purchases-refunds.create",
    component: () =>
        import ("@/view/pages/modules/purchases/purchaseRefund/Form.vue"),
    meta: {"role":["purchases_refunds.create","purchases_invoices.purchase_refund"]},
  },
  {
    path: "purchases/purchases-refunds/edit/:id",
    name: "purchases-refunds.edit",
    component: () =>
        import ("@/view/pages/modules/purchases/purchaseRefund/Form.vue"),
    meta: {"role":["purchases_refunds.update"]},
  },
  {
    path: "purchases/purchases-refunds/view/:id",
    name: "purchases-refunds.view",
    component: () =>
        import ("@/view/pages/modules/purchases/purchaseRefund/View.vue"),
    meta: {"role":["purchases_refunds.list"]},
  },
  {
    path: "purchases/purchase-configuration",
    name: "purchase-configuration.index",
    component: () =>
        import ("@/view/pages/modules/purchases/purchaseConfiguration/Index.vue"),
    meta: {"role":["purchases-settings.update"]},
  },
  {
    path: "purchases/subscriptions",
    name: "subscriptions.index",
    component: () =>
        import ("@/view/pages/modules/purchases/subscriptions/Index.vue"),
    meta: {"role":["subscriptions.list"]},
  },
  {
    path: "purchases/subscriptions/create",
    name: "subscriptions.create",
    component: () =>
        import ("@/view/pages/modules/purchases/subscriptions/Form.vue"),
    meta: {"role":["subscriptions.create"]},
  },
  {
    path: "purchases/subscriptions/edit/:id",
    name: "subscriptions.edit",
    component: () =>
        import ("@/view/pages/modules/purchases/subscriptions/Form.vue"),
    meta: {"role":["subscriptions.update"]},
  },
  {
    path: "purchases/purchases-requests",
    name: "purchases-requests.index",
    component: () =>
        import ("@/view/pages/modules/purchases/request/Index.vue"),
    meta: {"role":["purchases_requests.list"]},
  },
  {
    path: "purchases/purchases-requests/create",
    name: "purchases-requests.create",
    component: () =>
        import ("@/view/pages/modules/purchases/request/Form.vue"),
    meta: {"role":["purchases_requests.create"]},
  },
  {
    path: "purchases/purchases-requests/edit/:id",
    name: "purchases-requests.edit",
    component: () =>
        import ("@/view/pages/modules/purchases/request/Form.vue"),
    meta: {"role":["purchases_requests.update"]},
  },
  {
    path: "purchases/purchases-requests/view/:id",
    name: "purchases-requests.view",
    component: () =>
        import ("@/view/pages/modules/purchases/request/View.vue"),
    meta: {"role":["purchases_requests.list"]},
  },
  {
    path: "purchases/quotation-requests",
    name: "quotation-requests.index",
    component: () =>
        import ("@/view/pages/modules/purchases/quotationRequest/Index.vue"),
    meta: {"role":["quotations_requests.list"]},
  },
  {
    path: "purchases/quotation-requests/create",
    name: "quotation-requests.create",
    component: () =>
        import ("@/view/pages/modules/purchases/quotationRequest/Form.vue"),
    meta: {"role":["quotations_requests.create"]},
  },
  {
    path: "purchases/quotation-requests/edit/:id",
    name: "quotation-requests.edit",
    component: () =>
        import ("@/view/pages/modules/purchases/quotationRequest/Form.vue"),
    meta: {"role":["quotations_requests.update"]},
  },
  {
    path: "purchases/quotation-requests/view/:id",
    name: "quotation-requests.view",
    component: () =>
        import ("@/view/pages/modules/purchases/quotationRequest/View.vue"),
    meta: {"role":["quotations_requests.list"]},
  },
  {
    path: "purchases/purchases-orders",
    name: "purchases-orders.index",
    component: () =>
        import ("@/view/pages/modules/purchases/purchaseOrder/Index.vue"),
    meta: {"role":["purchase_orders.list"]},
  },
  {
    path: "purchases/purchases-orders/create",
    name: "purchases-orders.create",
    component: () =>
        import ("@/view/pages/modules/purchases/purchaseOrder/Form.vue"),
    meta: {"role":["purchase_orders.create"]},
  },
  {
    path: "purchases/purchases-orders/edit/:id",
    name: "purchases-orders.edit",
    component: () =>
        import ("@/view/pages/modules/purchases/purchaseOrder/Form.vue"),
    meta: {"role":["purchase_orders.update"]},
  },
  {
    path: "purchases/purchases-orders/view/:id",
    name: "purchases-orders.view",
    component: () =>
        import ("@/view/pages/modules/purchases/purchaseOrder/View.vue"),
    meta: {"role":["purchase_orders.list"]},
  },
  {
    path: "purchases/supplier-checking",
    name: "supplier-checking.index",
    component: () =>
        import ("@/view/pages/modules/purchases/supplierChecking/Index.vue"),
    meta: {"role":["supplier_checking.list"]},
  },
  {
    path: "purchases/supplier-checking/create",
    name: "supplier-checking.create",
    component: () =>
        import ("@/view/pages/modules/purchases/supplierChecking/Form.vue"),
    meta: {"role":["supplier_checking.create"]},
  },
  {
    path: "purchases/supplier-checking/edit/:id?",
    name: "supplier-checking.edit",
    component: () =>
        import ("@/view/pages/modules/purchases/supplierChecking/Form.vue"),
    meta: {"role":["supplier_checking.update"]},
  },
  {
    path: "purchases/supplier-checking-history",
    name: "supplier-checking-history.edit",
    component: () =>
        import ("@/view/pages/modules/purchases/supplierCheckingHistory/Index.vue"),
    meta: {"role":["supplier_checking.supplier_checking_history"]},
  },
  {
    path: "purchases/receipt-documents",
    name: "receipt-documents.index",
    component: () =>
        import ("@/view/pages/modules/purchases/receiptDocument/Index.vue"),
    meta: {"role":["receipt_documents.list"]},
  },
  {
    path: "purchases/receipt-documents/create/:purchase_invoice_id",
    name: "receipt-documents.create",
    component: () =>
        import ("@/view/pages/modules/purchases/receiptDocument/Form.vue"),
    meta: {"role":["receipt_documents.create","purchases_invoices.receipt_document"]},
  },
  {
    path: "purchases/receipt-documents/edit/:id",
    name: "receipt-documents.edit",
    component: () =>
        import ("@/view/pages/modules/purchases/receiptDocument/Form.vue"),
    meta: {"role":["receipt_documents.update"]},
  },
  {
    path: "purchases/purchase-quotations",
    name: "purchase-quotations.index",
    component: () =>
        import ("@/view/pages/modules/purchases/purchaseQuotations/Index.vue"),
    meta: {"role":["purchase_quotations.list"]},
  },
  {
    path: "purchases/purchase-quotations/create/:quotation_requests_id?",
    name: "purchase-quotations.create",
    component: () =>
        import ("@/view/pages/modules/purchases/purchaseQuotations/Form.vue"),
    meta: {"role":["purchase_quotations.create","purchase_quotations.convert_to_purchase_quotations"]},
  },
  {
    path: "purchases/purchase-quotations/edit/:id",
    name: "purchase-quotations.edit",
    component: () =>
        import ("@/view/pages/modules/purchases/purchaseQuotations/Form.vue"),
    meta: {"role":["purchase_quotations.update"]},
  },
  {
    path: "purchases/purchase-quotations/view/:id",
    name: "purchase-quotations.view",
    component: () =>
        import ("@/view/pages/modules/purchases/purchaseQuotations/View.vue"),
    meta: {"role":["purchase_quotations.list"]},
  },
  {
    path: "purchases/purchase-tax-settings",
    name: "purchase-tax-settings.list",
    component: () =>
        import ("@/view/pages/modules/purchases/purchaseTaxSettings/Form.vue"),
    meta: {"role":["tax_setting_purchase.update"]},
  },
  {
    path: "purchases/payment_purchase_invoices",
    name: "payment_purchase_invoices.index",
    component: () =>
        import ("@/view/pages/modules/purchases/paymentPurchaseInvoices/Index.vue"),
    meta: {"role":["payment_purchase_invoices.list"]},
  },
  {
    path: "purchases/payment_purchase_invoices/create/:purchase_invoice_id/:type?",
    name: "payment_purchase_invoices.create",
    component: () =>
        import ("@/view/pages/modules/purchases/paymentPurchaseInvoices/Form.vue"),
    meta: {"role":["payment_purchase_invoices.create","purchases_invoices.payment_purchases_invoices","purchases_refunds.payment_purchase_invoices"]},
  },
  {
    path: "purchases/payment_purchase_invoices/edit/:id/:type?",
    name: "payment_purchase_invoices.edit",
    component: () =>
        import ("@/view/pages/modules/purchases/paymentPurchaseInvoices/Form.vue"),
    meta: {"role":["payment_purchase_invoices.update"]},
  },
  {
    path: "purchases/payment_purchase_invoices/details/:id",
    name: "payment_purchase_invoices.details",
    component: () =>
        import ("@/view/pages/modules/purchases/paymentPurchaseInvoices/Details.vue"),
    meta: {"role":["payment_purchase_invoices.details"]},
  },
  {
    path: "purchases/payment_purchase_invoices/payment_purchase_details/:id",
    name: "payment_purchase_invoices.payment_purchase_details",
    component: () =>
        import ("@/view/pages/modules/purchases/paymentPurchaseInvoices/paymentPurchaseDetails.vue"),
    meta: {"role":["payment_purchase_invoices.details"]},
  },
];