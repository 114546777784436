export default [
  {
    path: "reports/stocks-report",
    name: "stocks-report.list",
    component: () =>
        import ("@/view/pages/modules/reports/stocksReport/Index.vue"),
    meta: {"role":["report_stores.report_stocks"]},
  },
  {
    path: "reports/inventory-transaction-report",
    name: "inventory-transaction-report.list",
    component: () =>
        import ("@/view/pages/modules/reports/inventoryTransactionReport/Index.vue"),
    meta: {"role":["report_stores.report_inventory_transaction"]},
  },
  {
    path: "reports/inventory-transaction-details",
    name: "inventory-transaction-details.list",
    component: () =>
        import ("@/view/pages/modules/reports/inventoryTransactionReport/Details.vue"),
    meta: {"role":["report_stores.report_inventory_transaction_details"]},
  },
  {
    path: "reports/sales-profit-report",
    name: "sales-profit-report.list",
    component: () =>
        import ("@/view/pages/modules/reports/salesProfitReport/Index.vue"),
    meta: {"role":["report_sales.sales_profit_report"]},
  },
  {
    path: "reports/profit-of-sales-item-report",
    name: "profit-of-sales-item-report.list",
    component: () =>
        import ("@/view/pages/modules/reports/profitOfSalesItemReport/Index.vue"),
    meta: {"role":["report_sales.profit_of_sales_item_report"]},
  },
  {
    path: "reports/estimated-inventory",
    name: "estimated-inventory.list",
    component: () =>
        import ("@/view/pages/modules/reports/estimatedInventory/Index.vue"),
    meta: {"role":["report_stores.report_estimated_inventory"]},
  },
  {
    path: "reports/summary-stock-balance",
    name: "summary-stock-balance.list",
    component: () =>
        import ("@/view/pages/modules/reports/summaryStockBalance/Index.vue"),
    meta: {"role":["report_stores.report_summary_stock_balance"]},
  },
  {
    path: "reports/product-average-cost",
    name: "product-average-cost.list",
    component: () =>
        import ("@/view/pages/modules/reports/productAverageCost/Index.vue"),
    meta: {"role":["report_stores.report_product_average_cost"]},
  },
  {
    path: "reports/daily-product-sales",
    name: "daily-product-sales.list",
    component: () =>
        import ("@/view/pages/modules/reports/dailyProductSales/Index.vue"),
    meta: {"role":["report_stores.report_daily_product_sales"]},
  },
  {
    path: "reports/sales-for-sales-report",
    name: "sales-for-sales-report.list",
    component: () =>
        import ("@/view/pages/modules/reports/salesForSalesReport/Index.vue"),
    meta: {"role":["report_sales.sales_for_sales_report"]},
  },
  {
    path: "store/reports",
    name: "store-reports",
    component: () =>
        import ("@/view/pages/modules/reports/storeReports/Index.vue"),
    meta: {"role":["report_stores.list"]},
  },
  {
    path: "reports/pos-reports-links",
    name: "pos-reports",
    component: () =>
        import ("@/view/pages/modules/reports/pos/posReports/Index.vue"),
    meta: {"role":["report_pos.list"]},
  },
  {
    path: "/clients/reports",
    name: "clients-reports",
    component: () =>
        import ("@/view/pages/modules/reports/ClientsReports/Index.vue"),
    meta: {"role":["report_clients.list"]},
  },
  {
    path: "reports/clients",
    name: "clients-reports.list",
    component: () =>
        import ("@/view/pages/modules/reports/Clients/Index.vue"),
    meta: {"role":["report_clients.clients_list"]},
  },
  {
    path: "reports/clients-appointments-report",
    name: "clients-appointments-report.list",
    component: () =>
        import ("@/view/pages/modules/reports/ClientsAppointmentsReport/Index.vue"),
    meta: {"role":["report_clients.report_clients_appointments"]},
  },
  {
    path: "reports/clients-installment-report",
    name: "clients-installment-report.list",
    component: () =>
        import ("@/view/pages/modules/reports/ClientsInstallmentsReport/Index.vue"),
    meta: {"role":["report_clients.report_clients_installment"]},
  },
  {
    path: "reports/clients-sales-report",
    name: "clients-sales-report.list",
    component: () =>
        import ("@/view/pages/modules/reports/ClientsSalesReport/Index.vue"),
    meta: {"role":["report_clients.report_clients_sales"]},
  },
  {
    path: "reports/clients-payments-report",
    name: "clients-payments-report.list",
    component: () =>
        import ("@/view/pages/modules/reports/ClientsPaymentsReport/Index.vue"),
    meta: {"role":["report_clients.report_clients_payments"]},
  },
  {
    path: "reports/clients-balance-report",
    name: "clients-balance-report.list",
    component: () =>
        import ("@/view/pages/modules/reports/ClientsBalanceReport/Index.vue"),
    meta: {"role":["report_clients.report_clients_balance"]},
  },
  {
    path: "reports/simple-clients-balance-report",
    name: "simple-clients-balance-report.list",
    component: () =>
        import ("@/view/pages/modules/reports/SimpleClientsBalanceReport/Index.vue"),
    meta: {"role":["report_clients.report_simple_customers_statment"]},
  },
  {
    path: "reports/simple-suppliers-balance-report",
    name: "simple-suppliers-balance-report.list",
    component: () =>
        import ("@/view/pages/modules/reports/SimpleSuppliersBalanceReport/Index.vue"),
    meta: {"role":["report_purchase.simple_supplier_balance"]},
  },
  {
    path: "reports/clients-debtors-report",
    name: "clients-debtors-report.list",
    component: () =>
        import ("@/view/pages/modules/reports/agedDebtors/Index.vue"),
    meta: {"role":["report_clients.report_clients_debtors"]},
  },
  {
    path: "reports/supplier-aged-ledger",
    name: "supplier-aged-ledger.list",
    component: () =>
        import ("@/view/pages/modules/reports/supplierAgedLedger/Index.vue"),
    meta: {"role":["report_purchase.supplier_aged_ledger"]},
  },
  {
    path: "reports/sales-reports-links",
    name: "sales-reports-links.list",
    component: () =>
        import ("@/view/pages/modules/reports/salesReports/Index.vue"),
    meta: {"role":["report_sales.list"]},
  },
  {
    path: "reports/sales-reports",
    name: "sales-reports.list",
    component: () =>
        import ("@/view/pages/modules/reports/salesByClient/Index.vue"),
    meta: {"role":["report_sales.sales_segmented_periodic"]},
  },
  {
    path: "reports/sales-revenue-reports",
    name: "sales-revenue-reports.list",
    component: () =>
        import ("@/view/pages/modules/reports/salesRevenue/Index.vue"),
    meta: {"role":["report_sales.revenue"]},
  },
  {
    path: "reports/sales-by-product-report",
    name: "sales-by-product-report.list",
    component: () =>
        import ("@/view/pages/modules/reports/salesByProduct/Index.vue"),
    meta: {"role":["report_sales.sales_segmented_periodic"]},
  },
  {
    path: "reports/sales-via-interval",
    name: "sales-via-interval-report.list",
    component: () =>
        import ("@/view/pages/modules/reports/salesViaInterval/Index.vue"),
    meta: {"role":["report_sales.sales_via_interval"]},
  },
  {
    path: "reports/payments-sales-reports",
    name: "payments-sales-reports.list",
    component: () =>
        import ("@/view/pages/modules/reports/paymentsSalesReport/Index.vue"),
    meta: {"role":["report_sales.report_payments_sales"]},
  },
  {
    path: "reports/sales-payments-report",
    name: "sales-payments-report.list",
    component: () =>
        import ("@/view/pages/modules/reports/SalesPaymentsReport/Index.vue"),
    meta: {"role":["report_sales.sales_payment"]},
  },
  {
    path: "reports/bonds-payments-details",
    name: "bonds-payments-details.list",
    component: () =>
        import ("@/view/pages/modules/reports/bondsPaymentsDetails/Index.vue"),
    meta: {"role":["report_sales.bonds_payments_details"]},
  },
  {
    path: "reports/suppliers-bonds-payments-details",
    name: "suppliers-bonds-payments-details.list",
    component: () =>
        import ("@/view/pages/modules/reports/suppliersBondsPaymentsDetails/Index.vue"),
    meta: {"role":["report_purchase.report_payments_purchases"]},
  },
  {
    path: "reports/purchase-reports-links",
    name: "purchase-reports-links.list",
    component: () =>
        import ("@/view/pages/modules/reports/purchaseReports/Index.vue"),
    meta: {"role":["report_purchase.list"]},
  },
  {
    path: "reports/suppliers-reports",
    name: "suppliers-reports.list",
    component: () =>
        import ("@/view/pages/modules/reports/suppliersReports/Index.vue"),
    meta: {"role":["report_purchase.suppliers_reports"]},
  },
  {
    path: "reports/suppliers-balance-reports",
    name: "suppliers-balance-reports.list",
    component: () =>
        import ("@/view/pages/modules/reports/suppliersBalanceReport/Index.vue"),
    meta: {"role":["report_purchase.report_suppliers_balance"]},
  },
  {
    path: "reports/suppliers-purchase-reports",
    name: "suppliers-purchase-reports.list",
    component: () =>
        import ("@/view/pages/modules/reports/suppliersPurchaseReports/Index.vue"),
    meta: {"role":["report_purchase.report_suppliers_purchase"]},
  },
  {
    path: "reports/paid-purchases-reports",
    name: "paid-purchases-reports.list",
    component: () =>
        import ("@/view/pages/modules/reports/paidPurchasesReports/Index.vue"),
    meta: {"role":["report_purchase.paid_purchases"]},
  },
  {
    path: "reports/purchases-segmented-reports",
    name: "purchases-segmented-reports.list",
    component: () =>
        import ("@/view/pages/modules/reports/purchasesSegmentedReports/Index.vue"),
    meta: {"role":["report_purchase.report_purchases_segmented"]},
  },
  {
    path: "reports/product-purchases-reports",
    name: "product-purchases-reports.list",
    component: () =>
        import ("@/view/pages/modules/reports/productsPurchaseReports/Index.vue"),
    meta: {"role":["report_purchase.report_product_purchases"]},
  },
  {
    path: "reports/payments-purchases-reports",
    name: "payments-purchases-reports.list",
    component: () =>
        import ("@/view/pages/modules/reports/purchasesPaymentsPeriodicReports/Index.vue"),
    meta: {"role":["report_purchase.report_payments_purchases"]},
  },
  {
    path: "reports/accounting-reports",
    name: "accounting-reports.list",
    component: () =>
        import ("@/view/pages/modules/reports/accounting/links/Index.vue"),
    meta: {"role":["report_accounting.list"]},
  },
  {
    path: "reports/journal-transactions",
    name: "journal-transactions.list",
    component: () =>
        import ("@/view/pages/modules/reports/accounting/journalTransactions/Index.vue"),
    meta: {"role":["report_accounting.report_journal_transactions"]},
  },
  {
    path: "reports/profit-loss-report",
    name: "profit-loss-report.list",
    component: () =>
        import ("@/view/pages/modules/reports/accounting/ProfitLossReport/Index.vue"),
    meta: {"role":["report_accounting.report_profit_loss"]},
  },
  {
    path: "reports/income-report",
    name: "income-report.list",
    component: () =>
        import ("@/view/pages/modules/reports/accounting/IncomeReport/Index.vue"),
    meta: {"role":["report_accounting.report_income"]},
  },
  {
    path: "reports/expenses-report",
    name: "expenses-report.list",
    component: () =>
        import ("@/view/pages/modules/reports/accounting/ExpensesReport/Index.vue"),
    meta: {"role":["report_accounting.report_expense"]},
  },
  {
    path: "reports/balance-sheet",
    name: "balance-sheet.list",
    component: () =>
        import ("@/view/pages/modules/reports/accounting/BalanceSheet/Index.vue"),
    meta: {"role":["report_accounting.report_balance_sheet"]},
  },
  {
    path: "reports/chart-accounts-directory",
    name: "chart-accounts-directory.list",
    component: () =>
        import ("@/view/pages/modules/reports/accounting/ChartAccounts/Index.vue"),
    meta: {"role":["report_accounting.report_chart_accounts"]},
  },
  {
    path: "reports/balance-balances-review",
    name: "balance-balances-review.list",
    component: () =>
        import ("@/view/pages/modules/reports/accounting/BalanceOfBalancesReview/Index.vue"),
    meta: {"role":["report_accounting.report_account_movements"]},
  },
  {
    path: "reports/taxes-report",
    name: "tax-report.list",
    component: () =>
        import ("@/view/pages/modules/reports/accounting/TaxReport/Index.vue"),
    meta: {"role":["report_accounting.report_tax"]},
  },
  {
    path: "reports/income-expenses-report",
    name: "income-expenses-report.list",
    component: () =>
        import ("@/view/pages/modules/reports/accounting/IncomeExpensesReport/Index.vue"),
    meta: {"role":["report_accounting.report_income_expenses"]},
  },
  {
    path: "reports/tax-declaration-report",
    name: "tax-declaration-report.list",
    component: () =>
        import ("@/view/pages/modules/reports/accounting/TaxDeclaration/Index.vue"),
    meta: {"role":["report_accounting.report_tax_declaration"]},
  },
  {
    path: "reports/financial-transactions-report",
    name: "financial-transactions-report.list",
    component: () =>
        import ("@/view/pages/modules/reports/FinancialTransactions/Index.vue"),
    meta: {"role":["report_financial_transactions.list"]},
  },
  {
    path: "reports/items-report",
    name: "items-report.list",
    component: () =>
        import ("@/view/pages/modules/reports/items/ItemsReport/Index.vue"),
    meta: {"role":["report_items.report_menu_items"]},
  },
  {
    path: "reports/system-activity-log",
    name: "system-activity-log.list",
    component: () =>
        import ("@/view/pages/modules/reports/ActivityLogReport/Index.vue"),
    meta: {"role":["report_system_activity_log.list"]},
  },
  {
    path: "reports/supplier-transactions-report",
    name: "supplier-transactions-report.list",
    component: () =>
        import ("@/view/pages/modules/reports/SupplierTransactionsReport/Index.vue"),
    meta: {"role":["report_purchase.report_supplier_transactions"]},
  },
  {
    path: "reports/customer-transactions-report",
    name: "customer-transactions-report.list",
    component: () =>
        import ("@/view/pages/modules/reports/customerTransactionsReport/Index.vue"),
    meta: {"role":["report_sales.report_customer_transactions"]},
  },
  {
    path: "reports/items-reports-links",
    name: "items-reports-links.list",
    component: () =>
        import ("@/view/pages/modules/reports/items/links/Index.vue"),
    meta: {"role":["report_items.list"]},
  },
  {
    path: "reports/items-transactions-report",
    name: "items-transactions-report.list",
    component: () =>
        import ("@/view/pages/modules/reports/items/ItemsTransactionsReport/Index.vue"),
    meta: {"role":["report_items.report_items_transactions"]},
  },
  {
    path: "reports/detailed-stock-transactions-per-product",
    name: "detailed-stock-transactions-per-product.list",
    component: () =>
        import ("@/view/pages/modules/reports/detailedStockTransactionsPerProduct/Index.vue"),
    meta: {"role":["report_stores.detailed_stock_transactions_per_product"]},
  },
  {
    path: "reports/total-pos-product-sales-details",
    name: "total-pos-product-sales-details.list",
    component: () =>
        import ("@/view/pages/modules/reports/pos/totalPosProductSalesDetails/Index.vue"),
    meta: {"role":["report_pos.total_product_sales_details"]},
  },
  {
    path: "reports/pos-shifts-sales-report",
    name: "pos-shifts-sales-report.list",
    component: () =>
        import ("@/view/pages/modules/reports/pos/posShiftsSalesReport/Index.vue"),
    meta: {"role":["report_pos.shifts_sales"]},
  },
  {
    path: "reports/total-pos-categories-profits-report",
    name: "total-pos-categories-profits-report.list",
    component: () =>
        import ("@/view/pages/modules/reports/pos/totalPOSCategoriesProfitsReport/Index.vue"),
    meta: {"role":["report_pos.total_categories_profits"]},
  },
  {
    path: "reports/detailed-pos-shifts-transactions-report",
    name: "detailed-pos-shifts-transactions-report.list",
    component: () =>
        import ("@/view/pages/modules/reports/pos/detailedPosShiftsTransactions/Index.vue"),
    meta: {"role":["report_pos.detailed_pos_shifts_transactions"]},
  },
  {
    path: "reports/total-pos-products-profits-report",
    name: "total-pos-products-profits-report.list",
    component: () =>
        import ("@/view/pages/modules/reports/pos/totalPOSProductsProfits/Index.vue"),
    meta: {"role":["report_pos.total_pos_products_profits"]},
  },
  {
    path: "reports/pos-shifts-profit-report",
    name: "pos-shifts-profit-report.list",
    component: () =>
        import ("@/view/pages/modules/reports/pos/posShiftsProfit/Index.vue"),
    meta: {"role":["report_pos.pos_shifts_profit"]},
  },
  {
    path: "/reports/main-reports",
    name: "main-reports.index",
    component: () =>
        import ("@/view/pages/modules/reports/reportsTabs/Index.vue"),
  },
];