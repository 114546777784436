import Vue from "vue";
import Router from "vue-router";
import Permissions from "@/core/services/permissions.service";
import store from "@/core/services/store";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";

Vue.use(Router);

function getContext(context) {
  let routes = [];
  context.keys().forEach((key) => {
    let moduleRoutes = context(key).default || []; // Ensure we handle empty exports
    routes.push(...moduleRoutes);
  });
  return routes;
}
// Dynamically import all modular route files
function loadModuleRoutesDashboard() {
  let context = require.context(`./router/dashboard/modules`, true, /\.js$/); // Look for .js files in ./modules/
  return getContext(context);
}
// Dynamically import all modular route files
function loadModuleRoutesPrint() {
  let context = require.context(`./router/print/modules`, true, /\.js$/); // Look for .js files in ./modules/
  return getContext(context);
}
// Dynamically import all modular route files
function loadModuleRoutesPointOfSales() {
  let context = require.context(`./router/pointOfSales/modules`, true, /\.js$/); // Look for .js files in ./modules/
  return getContext(context);
}

// Main router configuration
const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/modules/dashboard/Index.vue"),
        },
        ...loadModuleRoutesDashboard(), // Load all modular routes here
      ],
    },
    {
      path: "/print",
      component: () => import("@/view/layout/LayoutForPrint"),
      children: [
        ...loadModuleRoutesPrint(),
      ],
    },
    {
      path: "/point-of-sales",
      component: () => import("@/view/layout/LayoutForPointOfSales"),
      children: [
        ...loadModuleRoutesPointOfSales(),
      ],
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/view/pages/auth/login_pages/Login-1.vue"),
    },
    {
      path: "/profile-settings",
      name: "profile-settings",
      component: () => import("@/view/pages/auth/ProfileSettings.vue"),
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-404.vue"),
    },
    {
      path: "/403",
      name: "403",
      component: () => import("@/view/pages/error/Error-403.vue"),
    },
  ],
});

// Route guard for authentication and permissions
router.beforeEach((to, from, next) => {
  let verify_auth = [];
  if (!to.path.includes("print")) {
    verify_auth = store.dispatch(VERIFY_AUTH);
  }

  Promise.all([verify_auth]).then(() => {
    store.dispatch(RESET_LAYOUT_CONFIG);

    const role = to.meta && to.meta.role ? to.meta.role : [];
    const currentUserRoles = Permissions.getPermission();
    const hasPermission = role.some((r) => currentUserRoles.includes(r));

    if (hasPermission || role.length === 0) {
      return next();
    } else {
      return next({ path: "/403" });
    }
  });
});

export default router;