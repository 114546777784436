export default [
  {
    path: "/users/roles",
    name: "roles.index",
    component: () =>
        import ("@/view/pages/modules/roles/Index.vue"),
    meta: {"role":["roles.list"]},
  },
  {
    path: "/users/roles/edit/:id",
    name: "roles.edit",
    component: () =>
        import ("@/view/pages/modules/roles/Form.vue"),
    meta: {"role":["roles.update"]},
  },
];