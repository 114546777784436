export default [
  {
    path: "items/inventory-checking",
    name: "inventory-checking.index",
    component: () =>
        import ("@/view/pages/modules/items/inventoryChecking/Index.vue"),
    meta: {"role":["inventory_checking.list"]},
  },
  {
    path: "items/inventory-checking/edit/:id?",
    name: "inventory-checking.edit",
    component: () =>
        import ("@/view/pages/modules/items/inventoryChecking/Form.vue"),
    meta: {"role":["inventory_checking.update"]},
  },
  {
    path: "items/inventory-checking-history",
    name: "inventory-checking-history.index",
    component: () =>
        import ("@/view/pages/modules/items/inventoryCheckingHistory/Index.vue"),
    meta: {"role":["inventory_checking.inventory_checking_history"]},
  },
  {
    path: "/items/items",
    name: "items.index",
    component: () =>
        import ("@/view/pages/modules/items/items/Index.vue"),
    meta: {"role":["items.list"]},
  },
  {
    path: "/items/items/create",
    name: "items.create",
    component: () =>
        import ("@/view/pages/modules/items/items/Form.vue"),
    meta: {"role":["items.create"]},
  },
  {
    path: "/items/items/edit/:id/:previous_page_id?",
    name: "items.edit",
    component: () =>
        import ("@/view/pages/modules/items/items/Form.vue"),
    meta: {"role":["items.update"]},
  },
  {
    path: "/items/composite-items",
    name: "composite-items.index",
    component: () =>
        import ("@/view/pages/modules/items/compositeItems/Index.vue"),
    meta: {"role":["items.list"]},
  },
  {
    path: "/items/composite-items/create",
    name: "composite-items.create",
    component: () =>
        import ("@/view/pages/modules/items/compositeItems/Form.vue"),
    meta: {"role":["items.create"]},
  },
  {
    path: "/items/composite-items/edit/:id",
    name: "composite-items.edit",
    component: () =>
        import ("@/view/pages/modules/items/compositeItems/Form.vue"),
    meta: {"role":["items.update"]},
  },
  {
    path: "/items/bundle-and-device",
    name: "bundle-and-device.index",
    component: () =>
        import ("@/view/pages/modules/items/bundleAndDevice/Index.vue"),
    meta: {"role":["bundle_and_device.list"]},
  },
  {
    path: "/items/bundle-and-device/create",
    name: "bundle-and-device.create",
    component: () =>
        import ("@/view/pages/modules/items/bundleAndDevice/Form.vue"),
    meta: {"role":["bundle_and_device.create"]},
  },
  {
    path: "/items/bundle-and-device/edit/:id",
    name: "bundle-and-device.edit",
    component: () =>
        import ("@/view/pages/modules/items/bundleAndDevice/Form.vue"),
    meta: {"role":["bundle_and_device.update"]},
  },
  {
    path: "/items/bundle-divide/:bundle_id?",
    name: "bundle-divide.index",
    component: () =>
        import ("@/view/pages/modules/items/bundleDivide/Index.vue"),
    meta: {"role":["divide_bundle.list","bundle_and_device.bundle_device"]},
  },
  {
    path: "/items/bundle-divide/create/:bundle_id",
    name: "bundle-divide.create",
    component: () =>
        import ("@/view/pages/modules/items/bundleDivide/Form.vue"),
    meta: {"role":["divide_bundle.create","bundle_and_device.add_bundle_and_device"]},
  },
  {
    path: "/items/bundle-divide/edit/:id",
    name: "bundle-divide.edit",
    component: () =>
        import ("@/view/pages/modules/items/bundleDivide/Form.vue"),
    meta: {"role":["divide_bundle.update","bundle_and_device.bundle_device"]},
  },
  {
    path: "/items/collected-items",
    name: "collected-items.index",
    component: () =>
        import ("@/view/pages/modules/items/collectedItems/Index.vue"),
    meta: {"role":["collected_items.list"]},
  },
  {
    path: "/items/collected-items/create",
    name: "collected-items.create",
    component: () =>
        import ("@/view/pages/modules/items/collectedItems/Form.vue"),
    meta: {"role":["collected_items.create"]},
  },
  {
    path: "/items/collected-items/edit/:id",
    name: "collected-items.edit",
    component: () =>
        import ("@/view/pages/modules/items/collectedItems/Form.vue"),
    meta: {"role":["collected_items.update"]},
  },
  {
    path: "/items/aspects",
    name: "aspects.index",
    component: () =>
        import ("@/view/pages/modules/items/aspects/Index.vue"),
    meta: {"role":["aspect.list"]},
  },
  {
    path: "/items/aspects/create",
    name: "aspects.create",
    component: () =>
        import ("@/view/pages/modules/items/aspects/Form.vue"),
    meta: {"role":["aspect.create"]},
  },
  {
    path: "/items/aspects/edit/:id",
    name: "aspects.edit",
    component: () =>
        import ("@/view/pages/modules/items/aspects/Form.vue"),
    meta: {"role":["aspect.update"]},
  },
  {
    path: "/items/price-lists",
    name: "price-lists.index",
    component: () =>
        import ("@/view/pages/modules/items/priceList/Index.vue"),
    meta: {"role":["price-list.list"]},
  },
  {
    path: "/items/price-lists/create",
    name: "price-lists.create",
    component: () =>
        import ("@/view/pages/modules/items/priceList/Form.vue"),
    meta: {"role":["price-list.create"]},
  },
  {
    path: "/items/price-lists/edit/:id",
    name: "price-lists.edit",
    component: () =>
        import ("@/view/pages/modules/items/priceList/Form.vue"),
    meta: {"role":["price-list.update"]},
  },
  {
    path: "/items/inventory-transactions",
    name: "inventory-transactions.index",
    component: () =>
        import ("@/view/pages/modules/items/inventoryTransactions/Index.vue"),
    meta: {"role":["inventory-transactions.list"]},
  },
  {
    path: "/items/inventory-transactions/create",
    name: "inventory-transactions.create",
    component: () =>
        import ("@/view/pages/modules/items/inventoryTransactions/Form.vue"),
    meta: {"role":["inventory-transactions.create"]},
  },
  {
    path: "/items/inventory-transactions/edit/:id",
    name: "inventory-transactions.edit",
    component: () =>
        import ("@/view/pages/modules/items/inventoryTransactions/Form.vue"),
    meta: {"role":["inventory-transactions.update"]},
  },
  {
    path: "/items/item_label_configuration",
    name: "item_label_configuration.index",
    component: () =>
        import ("@/view/pages/modules/items/configuration/Form.vue"),
    meta: {"role":["item_configuration.update"]},
  },
  {
    path: "/items/item_table_settings",
    name: "item_table_settings.index",
    component: () =>
        import ("@/view/pages/modules/items/configuration/TableSettings.vue"),
    meta: {"role":["item_configuration.update"]},
  },
  {
    path: "/items/inventory-monitoring",
    name: "inventory-monitoring.index",
    component: () =>
        import ("@/view/pages/modules/items/inventoryMonitoring/Index.vue"),
    meta: {"role":["inventory-monitoring.list"]},
  },
  {
    path: "/items/inventory-monitoring/create",
    name: "inventory-monitoring.create",
    component: () =>
        import ("@/view/pages/modules/items/inventoryMonitoring/Form.vue"),
    meta: {"role":["inventory-monitoring.create"]},
  },
  {
    path: "/items/inventory-monitoring/edit/:id",
    name: "inventory-monitoring.edit",
    component: () =>
        import ("@/view/pages/modules/items/inventoryMonitoring/Form.vue"),
    meta: {"role":["inventory-monitoring.update"]},
  },
  {
    path: "items/open-stock-items",
    name: "open-stock-items.index",
    component: () =>
        import ("@/view/pages/modules/items/openStockItems/Index.vue"),
    meta: {"role":["item_open_stock.list"]},
  },
  {
    path: "items/open-quantity-history",
    name: "open-quantity-history.index",
    component: () =>
        import ("@/view/pages/modules/items/openStockItems/IndexHistory.vue"),
    meta: {"role":["item_open_stock.list"]},
  },
  {
    path: "items/open-stock-items/create",
    name: "open-stock-items.create",
    component: () =>
        import ("@/view/pages/modules/items/openStockItems/Form.vue"),
    meta: {"role":["item_open_stock.create"]},
  },
  {
    path: "items/open-stock-items/edit/:id",
    name: "open-stock-items.edit",
    component: () =>
        import ("@/view/pages/modules/items/openStockItems/Form.vue"),
    meta: {"role":["item_open_stock.update"]},
  },
  {
    path: "items/open-stock-items/view/:id",
    name: "open-stock-items.view",
    component: () =>
        import ("@/view/pages/modules/items/openStockItems/View.vue"),
    meta: {"role":["item_open_stock.list"]},
  },
  {
    path: "items/export-import-items",
    name: "export-import-items.index",
    component: () =>
        import ("@/view/pages/modules/items/exportImport/Index.vue"),
    meta: {"role":["export_import_items.list"]},
  },
  {
    path: "items/import-items",
    name: "import-items.index",
    component: () =>
        import ("@/view/pages/modules/items/importItems/Index.vue"),
    meta: {"role":["items.export_import_items"]},
  },
  {
    path: "/items/import-open-stock-items",
    name: "import-open-stock-items.index",
    component: () =>
        import ("@/view/pages/modules/items/importOpenStockItems/Index.vue"),
    meta: {"role":["item_open_stock.import_quantities"]},
  },
  {
    path: "/items/price-lists-laundry-services",
    name: "price-lists-laundry-services.index",
    component: () =>
        import ("@/view/pages/modules/items/laundryServicesPriceList/Index.vue"),
    meta: {"role":["price_list_laundry_services.list"]},
  },
  {
    path: "/items/price-lists-laundry-services/create",
    name: "price-lists-laundry-services.create",
    component: () =>
        import ("@/view/pages/modules/items/laundryServicesPriceList/Form.vue"),
    meta: {"role":["price_list_laundry_services.create"]},
  },
  {
    path: "/items/price-lists-laundry-services/edit/:id",
    name: "price-lists-laundry-services.edit",
    component: () =>
        import ("@/view/pages/modules/items/laundryServicesPriceList/Form.vue"),
    meta: {"role":["price_list_laundry_services.update"]},
  },
  {
    path: "items/general-item-configurations",
    name: "general-item-configurations.edit",
    component: () =>
        import ("@/view/pages/modules/items/generalConfiguration/Form.vue"),
    meta: {"role":["general_items_configuration.update"]},
  },
];