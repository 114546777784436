export default [
  {
    path: "/customers/customers",
    name: "customers.index",
    component: () =>
        import ("@/view/pages/modules/customers/customers/Index.vue"),
    meta: {"role":["customers.list"]},
  },
  {
    path: "/customers/customers/create",
    name: "customers.create",
    component: () =>
        import ("@/view/pages/modules/customers/customers/Form.vue"),
    meta: {"role":["customers.create"]},
  },
  {
    path: "/customers/customers/edit/:id",
    name: "customers.edit",
    component: () =>
        import ("@/view/pages/modules/customers/customers/Form.vue"),
    meta: {"role":["customers.update"]},
  },
  {
    path: "/customers/customers/view/:id",
    name: "customers.view",
    component: () =>
        import ("@/view/pages/modules/customers/customers/View.vue"),
    meta: {"role":["customers.list"]},
  },
  {
    path: "/customer/send/mail/:id",
    name: "customer.sendMail",
    component: () =>
        import ("@/view/pages/modules/customers/customers/SendMail.vue"),
    meta: {"role":["customers.update"]},
  },
  {
    path: "/customers/customers-status",
    name: "customers-status.index",
    component: () =>
        import ("@/view/pages/modules/customers/customersStatus/Form.vue"),
    meta: {"role":["customer-status.update"]},
  },
  {
    path: "/customers/customer-attributes",
    name: "customer-attributes.index",
    component: () =>
        import ("@/view/pages/modules/customers/customerAttributes/Form.vue"),
    meta: {"role":["customer-attributes.update"]},
  },
  {
    path: "/customers/customer*-features",
    name: "customer-features.index",
    component: () =>
        import ("@/view/pages/modules/customers/customersFeature/Form.vue"),
    meta: {"role":["customer-features.update"]},
  },
  {
    path: "/customers/appointments",
    name: "appointments.index",
    component: () =>
        import ("@/view/pages/modules/customers/appointments/Index.vue"),
    meta: {"role":["appointments.list"]},
  },
  {
    path: "/customers/appointments/create/:sale_invoice_id?",
    name: "appointments.create",
    component: () =>
        import ("@/view/pages/modules/customers/appointments/Form.vue"),
    meta: {"role":["appointments.create"]},
  },
  {
    path: "/customers/appointments/edit/:id",
    name: "appointments.edit",
    component: () =>
        import ("@/view/pages/modules/customers/appointments/Form.vue"),
    meta: {"role":["appointments.update"]},
  },
  {
    path: "sales/estimations/create/:type_id/:type",
    name: "appointment.create",
    component: () =>
        import ("@/view/pages/modules/customers/appointments/Form.vue"),
  },
  {
    path: "sales/appointment/create/:type_id/:type",
    name: "appointment.create",
    component: () =>
        import ("@/view/pages/modules/customers/appointments/Form.vue"),
    meta: {"role":["schedule_appointment.create","sales_invoices.schedule_appointment","estimations.schedule_appointment","general_sales.schedule_appointment"]},
  },
  {
    path: "customers/customer-checking",
    name: "customer-checking.index",
    component: () =>
        import ("@/view/pages/modules/customers/customerChecking/Index.vue"),
    meta: {"role":["customer_checking.list"]},
  },
  {
    path: "customers/customer-checking/create",
    name: "customer-checking.create",
    component: () =>
        import ("@/view/pages/modules/customers/customerChecking/Form.vue"),
    meta: {"role":["customer_checking.create"]},
  },
  {
    path: "customers/customer-checking/edit/:id?",
    name: "customer-checking.edit",
    component: () =>
        import ("@/view/pages/modules/customers/customerChecking/Form.vue"),
    meta: {"role":["customer_checking.update"]},
  },
  {
    path: "customers/customer-checking-history",
    name: "customer-checking-history.index",
    component: () =>
        import ("@/view/pages/modules/customers/customerCheckingHistory/Index.vue"),
    meta: {"role":["customer_checking.customer_checking_history"]},
  },
  {
    path: "customers/export-import-customers",
    name: "export-import-customers.index",
    component: () =>
        import ("@/view/pages/modules/customers/importCustomers/Index.vue"),
    meta: {"role":["customers.export_import_customers"]},
  },
];