export default [
  {
    path: "/users/users",
    name: "users.index",
    component: () =>
        import ("@/view/pages/modules/users/Index.vue"),
    meta: {"role":["users.list"]},
  },
  {
    path: "/users/users/create",
    name: "users.create",
    component: () =>
        import ("@/view/pages/modules/users/Form.vue"),
    meta: {"role":["users.list"]},
  },
  {
    path: "/users/users/edit/:id",
    name: "users.edit",
    component: () =>
        import ("@/view/pages/modules/users/Form.vue"),
    meta: {"role":["users.update"]},
  },
  {
    path: "/users/send/message",
    name: "users.sendMessage",
    component: () =>
        import ("@/view/pages/modules/users/SendMessage.vue"),
    meta: {"role":["send_message_users.update"]},
  },
  {
    path: "/users/customer-permissions/:user_id",
    name: "customer-permissions.list",
    component: () =>
        import ("@/view/pages/modules/users/customerPermissions/Form.vue"),
    meta: {"role":["users.customer_permissions"]},
  },
  {
    path: "/users/supplier-permissions/:user_id",
    name: "supplier-permissions.list",
    component: () =>
        import ("@/view/pages/modules/users/supplierPermissions/Form.vue"),
    meta: {"role":["users.supplier_permissions"]},
  },
  {
    path: "/users/pos-permissions/:user_id",
    name: "pos-permissions.list",
    component: () =>
        import ("@/view/pages/modules/users/posPermissions/Form.vue"),
    meta: {"role":["users.pos_permissions"]},
  },
  {
    path: "/users/invoices-bonds-management/:user_id",
    name: "invoices-bonds-management.list",
    component: () =>
        import ("@/view/pages/modules/users/invoicesBondsManagement/Form.vue"),
    meta: {"role":["users.invoices_bonds_management"]},
  },
  {
    path: "/notifications",
    name: "notifications.index",
    component: () =>
        import ("@/view/pages/modules/users/Notifications.vue"),
  },
  {
    path: "users/data-access-configuration",
    name: "data-access-configuration.create",
    component: () =>
        import ("@/view/pages/modules/users/dataAccess/Form.vue"),
  },
  {
    path: "users/default-data/:id",
    name: "default-data.create",
    component: () =>
        import ("@/view/pages/modules/users/defaultData/Form.vue"),
    meta: {"role":["users.default_data"]},
  },
];