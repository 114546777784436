export default [
  {
    path: "/settings/constants",
    name: "constants.index",
    component: () =>
        import ("@/view/pages/modules/settings/constants/Index.vue"),
    meta: {"role":["constants.list"]},
  },
  {
    path: "/settings/units",
    name: "units.index",
    component: () =>
        import ("@/view/pages/modules/settings/units/Index.vue"),
    meta: {"role":["units.list"]},
  },
  {
    path: "/settings/currencies",
    name: "currencies.index",
    component: () =>
        import ("@/view/pages/modules/settings/currencies/Index.vue"),
    meta: {"role":["currencies.list"]},
  },
  {
    path: "/settings/code-setting",
    name: "code_setting.index",
    component: () =>
        import ("@/view/pages/modules/settings/codeSetting/Index.vue"),
  },
  {
    path: "/settings/procedures",
    name: "procedures.index",
    component: () =>
        import ("@/view/pages/modules/settings/procedures/Index.vue"),
    meta: {"role":["procedures.list"]},
  },
  {
    path: "/settings/key-generation",
    name: "key_generation.index",
    component: () =>
        import ("@/view/pages/modules/settings/key_generation/Index.vue"),
    meta: {"role":["key_generations.list"]},
  },
  {
    path: "/settings/print-settings",
    name: "print_setting.index",
    component: () =>
        import ("@/view/pages/modules/settings/printSettings/Index.vue"),
    meta: {"role":["print_setting.list"]},
  },
  {
    path: "/settings/print-settings-details/:id/:slug",
    name: "print_setting.details",
    component: () =>
        import ("@/view/pages/modules/settings/printSettings/Details.vue"),
    meta: {"role":["print_setting.list"]},
  },
  {
    path: "/settings/payments",
    name: "payments.index",
    component: () =>
        import ("@/view/pages/modules/settings/payments/Index.vue"),
    meta: {"role":["payments.list"]},
  },
  {
    path: "/settings/taxes",
    name: "taxes.index",
    component: () =>
        import ("@/view/pages/modules/settings/taxes/Index.vue"),
    meta: {"role":["taxes.list"]},
  },
  {
    path: "/settings/taxes/create",
    name: "taxes.create",
    component: () =>
        import ("@/view/pages/modules/settings/taxes/Form.vue"),
    meta: {"role":["taxes.create"]},
  },
  {
    path: "/settings/taxes/edit/:id",
    name: "taxes.edit",
    component: () =>
        import ("@/view/pages/modules/settings/taxes/Form.vue"),
    meta: {"role":["taxes.update"]},
  },
  {
    path: "/settings/countries",
    name: "countries.index",
    component: () =>
        import ("@/view/pages/modules/settings/countries/Index.vue"),
    meta: {"role":["countries.list"]},
  },
  {
    path: "/settings/countries/edit/:id",
    name: "countries.edit",
    component: () =>
        import ("@/view/pages/modules/settings/countries/Form.vue"),
    meta: {"role":["countries.update"]},
  },
  {
    path: "/settings/cities/:code2",
    name: "cities.index",
    component: () =>
        import ("@/view/pages/modules/settings/cities/Index.vue"),
    meta: {"role":["cities.list"]},
  },
  {
    path: "/settings/branches",
    name: "branches.index",
    component: () =>
        import ("@/view/pages/modules/settings/branches/Index.vue"),
    meta: {"role":["branches.list"]},
  },
  {
    path: "/settings/branches/create",
    name: "branches.create",
    component: () =>
        import ("@/view/pages/modules/settings/branches/Form.vue"),
    meta: {"role":["branches.create"]},
  },
  {
    path: "/settings/branches/edit/:id",
    name: "branches.edit",
    component: () =>
        import ("@/view/pages/modules/settings/branches/Form.vue"),
    meta: {"role":["branches.update"]},
  },
  {
    path: "/settings/smtp",
    name: "smtp.index",
    component: () =>
        import ("@/view/pages/modules/settings/smtp/Form.vue"),
    meta: {"role":["smtps.update"]},
  },
  {
    path: "/settings/barcode-setting",
    name: "barcode_setting.index",
    component: () =>
        import ("@/view/pages/modules/settings/barcodeSetting/Form.vue"),
    meta: {"role":["barcode_setting.update"]},
  },
  {
    path: "/settings/template_design",
    name: "template_design.index",
    component: () =>
        import ("@/view/pages/modules/settings/template_design/Form.vue"),
    meta: {"role":["template_design.update"]},
  },
  {
    path: "/items/inventory_statistics",
    name: "inventory_statistics.index",
    component: () =>
        import ("@/view/pages/modules/settings/inventoryStatistics/Index.vue"),
    meta: {"role":["items.inventory_statistics"]},
  },
  {
    path: "items/inventory_statistics/view/:id",
    name: "inventory_statistics.view",
    component: () =>
        import ("@/view/pages/modules/settings/inventoryStatistics/View.vue"),
    meta: {"role":["items.inventory_statistics"]},
  },
  {
    path: "/items/inventory_statistics_tracking",
    name: "inventory_statistics.index",
    component: () =>
        import ("@/view/pages/modules/settings/inventoryStatisticsTraking/Index.vue"),
    meta: {"role":["items.inventory_statistics"]},
  },
  {
    path: "/settings/inventories",
    name: "inventories.index",
    component: () =>
        import ("@/view/pages/modules/settings/inventories/Index.vue"),
    meta: {"role":["inventories.list"]},
  },
  {
    path: "/settings/inventories/create",
    name: "inventories.create",
    component: () =>
        import ("@/view/pages/modules/settings/inventories/Form.vue"),
    meta: {"role":["inventories.create"]},
  },
  {
    path: "/settings/inventories/edit/:id",
    name: "inventories.edit",
    component: () =>
        import ("@/view/pages/modules/settings/inventories/Form.vue"),
    meta: {"role":["inventories.update"]},
  },
  {
    path: "/settings/brands",
    name: "brands.index",
    component: () =>
        import ("@/view/pages/modules/settings/brands/Index.vue"),
    meta: {"role":["brands.list"]},
  },
  {
    path: "/settings/api-configurations",
    name: "apiConfigurations.index",
    component: () =>
        import ("@/view/pages/modules/settings/apiConfiguration/Index.vue"),
    meta: {"role":["api-configurations.list"]},
  },
  {
    path: "/settings/api-configurations/create",
    name: "apiConfigurations.create",
    component: () =>
        import ("@/view/pages/modules/settings/apiConfiguration/Form.vue"),
    meta: {"role":["api-configurations.create"]},
  },
  {
    path: "/settings/api-configurations/edit/:id",
    name: "apiConfigurations.edit",
    component: () =>
        import ("@/view/pages/modules/settings/apiConfiguration/Form.vue"),
    meta: {"role":["api-configurations.update"]},
  },
  {
    path: "/settings/api-internal-configurations",
    name: "apiInternalConfigurations.index",
    component: () =>
        import ("@/view/pages/modules/settings/apiInternalConfigurations/Index.vue"),
    meta: {"role":["api_internal_configurations.list"]},
  },
  {
    path: "/settings/api-internal-configurations/create",
    name: "apiInternalConfigurations.create",
    component: () =>
        import ("@/view/pages/modules/settings/apiInternalConfigurations/Form.vue"),
    meta: {"role":["api_internal_configurations.create"]},
  },
  {
    path: "/settings/api-internal-configurations/edit/:id",
    name: "apiInternalConfigurations.edit",
    component: () =>
        import ("@/view/pages/modules/settings/apiInternalConfigurations/Form.vue"),
    meta: {"role":["api_internal_configurations.update"]},
  },
  {
    path: "/settings/api-endpoint-configurations/:provider_id",
    name: "apiEndpointConfigurations.index",
    component: () =>
        import ("@/view/pages/modules/settings/apiConfiguration/apiEndpointConfiguration/Index.vue"),
    meta: {"role":["api-endpoint-configurations.list"]},
  },
  {
    path: "/settings/api-endpoint-configurations/:provider_id/create",
    name: "apiEndpointConfigurations.create",
    component: () =>
        import ("@/view/pages/modules/settings/apiConfiguration/apiEndpointConfiguration/Form.vue"),
    meta: {"role":["api-endpoint-configurations.create"]},
  },
  {
    path: "/settings/api-endpoint-configurations/:provider_id/edit/:id",
    name: "apiEndpointConfigurations.edit",
    component: () =>
        import ("@/view/pages/modules/settings/apiConfiguration/apiEndpointConfiguration/Form.vue"),
    meta: {"role":["api-endpoint-configurations.update"]},
  },
  {
    path: "/settings/manufacturers",
    name: "manufacturers.index",
    component: () =>
        import ("@/view/pages/modules/settings/manufacturers/Index.vue"),
    meta: {"role":["manufacturers.list"]},
  },
  {
    path: "/settings/categories",
    name: "categories.index",
    component: () =>
        import ("@/view/pages/modules/settings/categories/Form.vue"),
    meta: {"role":["categories.list"]},
  },
  {
    path: "/settings/categories/list",
    name: "categories-list.index",
    component: () =>
        import ("@/view/pages/modules/settings/categories/Index.vue"),
    meta: {"role":["categories.list"]},
  },
  {
    path: "/settings/laundry-services-prices/:category_id",
    name: "laundry-services-prices.index",
    component: () =>
        import ("@/view/pages/modules/settings/laundryServicesPricing/Form.vue"),
    meta: {"role":["categories.list"]},
  },
  {
    path: "settings/custom/email/:type_id/:type",
    name: "custom.email",
    component: () =>
        import ("@/view/pages/modules/settings/customEmail/Form.vue"),
    meta: {"role":["sales_invoices.send_mail","payment_sales_invoices.send_mail","payment_purchase_invoices.send_mail","payable_cheques.send_mail","receivable_cheques.send_mail","general_sales.send_mail"]},
  },
  {
    path: "accounting/document-type-setting",
    name: "document-type-setting.list",
    component: () =>
        import ("@/view/pages/modules/settings/documentTypeSetting/Index.vue"),
    meta: {"role":["document_type_setting.list"]},
  },
  {
    path: "accounting/document-type-setting/create",
    name: "document-type-setting.create",
    component: () =>
        import ("@/view/pages/modules/settings/documentTypeSetting/Form.vue"),
    meta: {"role":["document_type_setting.create"]},
  },
  {
    path: "accounting/document-type-setting/edit/:id",
    name: "document-type-setting.edit",
    component: () =>
        import ("@/view/pages/modules/settings/documentTypeSetting/Form.vue"),
    meta: {"role":["document_type_setting.update"]},
  },
  {
    path: "accounting/journals-setting",
    name: "journals-setting.list",
    component: () =>
        import ("@/view/pages/modules/settings/journalsSetting/Index.vue"),
    meta: {"role":["journal_settings.list"]},
  },
  {
    path: "accounting/journals-setting/create",
    name: "journals-setting.create",
    component: () =>
        import ("@/view/pages/modules/settings/journalsSetting/Form.vue"),
    meta: {"role":["journal_settings.create"]},
  },
  {
    path: "accounting/journals-setting/edit/:id",
    name: "journals-setting.edit",
    component: () =>
        import ("@/view/pages/modules/settings/journalsSetting/Form.vue"),
    meta: {"role":["journal_settings.update"]},
  },
  {
    path: "/settings/currency-exchange",
    name: "currency-exchange.index",
    component: () =>
        import ("@/view/pages/modules/settings/currencyExchange/Index.vue"),
    meta: {"role":["currencies_convert.list"]},
  },
  {
    path: "/settings/mobile-app-settings",
    name: "mobile-app-settings.index",
    component: () =>
        import ("@/view/pages/modules/settings/mobileApp/Index.vue"),
    meta: {"role":["mobile_app_setting.list"]},
  },
  {
    path: "/settings/mobile-app-settings/create",
    name: "mobile-app-settings.create",
    component: () =>
        import ("@/view/pages/modules/settings/mobileApp/Form.vue"),
    meta: {"role":["mobile_app_setting.create"]},
  },
  {
    path: "settings/notifications-config",
    name: "notifications-config.index",
    component: () =>
        import ("@/view/pages/modules/settings/notificationsConfig/Index.vue"),
    meta: {"role":["notification_config.list"]},
  },
  {
    path: "settings/notifications-config/create",
    name: "notifications-config.create",
    component: () =>
        import ("@/view/pages/modules/settings/notificationsConfig/Form.vue"),
    meta: {"role":["notification_config.create"]},
  },
  {
    path: "settings/notifications-config/edit/:id",
    name: "notifications-config.edit",
    component: () =>
        import ("@/view/pages/modules/settings/notificationsConfig/Form.vue"),
    meta: {"role":["notification_config.update"]},
  },
  {
    path: "settings/custom-notifications",
    name: "notifications-config.list",
    component: () =>
        import ("@/view/pages/modules/settings/notificationsConfig/Custom.vue"),
    meta: {"role":["notification_config.create"]},
  },
  {
    path: "settings/available-features",
    name: "available-features.index",
    component: () =>
        import ("@/view/pages/modules/settings/availableFeatures/Index.vue"),
    meta: {"role":["available_features.list"]},
  },
  {
    path: "settings/available-sub-features/:id",
    name: "available-sub-features.index",
    component: () =>
        import ("@/view/pages/modules/settings/availableFeatures/subFeature/Index.vue"),
    meta: {"role":["available_sub_features.list"]},
  },
  {
    path: "/settings/branch-config/:id",
    name: "branch-config.index",
    component: () =>
        import ("@/view/pages/modules/settings/branchesConfig/Form.vue"),
    meta: {"role":["branches.branch_config"]},
  },
  {
    path: "/settings/general-group",
    name: "general-group.index",
    component: () =>
        import ("@/view/pages/modules/settings/generalGroup/Index.vue"),
    meta: {"role":["general_group.list"]},
  },
  {
    path: "settings/module-data-access",
    name: "module-data-access.list",
    component: () =>
        import ("@/view/pages/modules/settings/moduleDataAccess/Index.vue"),
    meta: {"role":["modules_setting.list"]},
  },
  {
    path: "settings/module-data-access/create",
    name: "module-data-access.create",
    component: () =>
        import ("@/view/pages/modules/settings/moduleDataAccess/Form.vue"),
    meta: {"role":["modules_setting.create"]},
  },
  {
    path: "settings/module-data-access/edit/:id",
    name: "module-data-access.edit",
    component: () =>
        import ("@/view/pages/modules/settings/moduleDataAccess/Form.vue"),
    meta: {"role":["modules_setting.update"]},
  },
  {
    path: "/settings/taxes-rules",
    name: "taxes-rules.index",
    component: () =>
        import ("@/view/pages/modules/settings/taxesRules/Index.vue"),
    meta: {"role":["taxes_rules.list"]},
  },
  {
    path: "/settings/delimiters",
    name: "delimiters.index",
    component: () =>
        import ("@/view/pages/modules/settings/delimiters/Index.vue"),
    meta: {"role":["delimiters.list"]},
  },
  {
    path: "/settings/delimiters/create",
    name: "delimiters.create",
    component: () =>
        import ("@/view/pages/modules/settings/delimiters/Form.vue"),
    meta: {"role":["delimiters.create"]},
  },
  {
    path: "/settings/delimiters/edit/:id",
    name: "delimiters.edit",
    component: () =>
        import ("@/view/pages/modules/settings/delimiters/Form.vue"),
    meta: {"role":["delimiters.update"]},
  },
  {
    path: "/settings/laundry-services",
    name: "laundry_services.index",
    component: () =>
        import ("@/view/pages/modules/settings/laundryServices/Index.vue"),
    meta: {"role":["laundry_services.create"]},
  },
  {
    path: "/settings/laundry-services/create",
    name: "laundry_services.create",
    component: () =>
        import ("@/view/pages/modules/settings/laundryServices/Form.vue"),
    meta: {"role":["laundry_services.create"]},
  },
  {
    path: "/settings/laundry-services/edit/:id",
    name: "laundry_services.edit",
    component: () =>
        import ("@/view/pages/modules/settings/laundryServices/Form.vue"),
    meta: {"role":["laundry_services.update"]},
  },
  {
    path: "/settings/attached-reasons",
    name: "attached-reasons.index",
    component: () =>
        import ("@/view/pages/modules/settings/attachedReasons/Index.vue"),
    meta: {"role":["attached_reasons.create"]},
  },
  {
    path: "/settings/laundry-services-colors",
    name: "laundry-services-colors.index",
    component: () =>
        import ("@/view/pages/modules/settings/laundryServicesColors/Form.vue"),
    meta: {"role":["laundry_services_colors.update"]},
  },
  {
    path: "/settings/discount",
    name: "discounts-setting.index",
    component: () =>
        import ("@/view/pages/modules/settings/discount/Index.vue"),
    meta: {"role":["discounts_setting.list"]},
  },
  {
    path: "/settings/discount/create",
    name: "discounts-setting.create",
    component: () =>
        import ("@/view/pages/modules/settings/discount/Form.vue"),
    meta: {"role":["discounts_setting.create"]},
  },
  {
    path: "settings/cash-category",
    name: "cash_category.index",
    component: () =>
        import ("@/view/pages/modules/settings/cashCategory/Index.vue"),
    meta: {"role":["cash_category.list"]},
  },
  {
    path: "settings/invoice-bond-configuration",
    name: "invoice-bond-configuration.index",
    component: () =>
        import ("@/view/pages/modules/settings/invoicesBondsConfiguration/Index.vue"),
    meta: {"role":["invoice_bond_configuration.list"]},
  },
  {
    path: "settings/invoice-bond-configuration/edit/:id",
    name: "invoice-bond-configuration.edit",
    component: () =>
        import ("@/view/pages/modules/settings/invoicesBondsConfiguration/Form.vue"),
    meta: {"role":["invoice_bond_configuration.update"]},
  },
  {
    path: "settings/basic-status-management",
    name: "basic-status-management.index",
    component: () =>
        import ("@/view/pages/modules/settings/basicStatusManagement/Index.vue"),
    meta: {"role":["basic_status_management.list"]},
  },
  {
    path: "settings/basic-status-management/edit/:id",
    name: "basic-status-management.edit",
    component: () =>
        import ("@/view/pages/modules/settings/basicStatusManagement/Form.vue"),
    meta: {"role":["basic_status_management.update"]},
  },
  {
    path: "settings/document-status-paths",
    name: "document-status-paths.index",
    component: () =>
        import ("@/view/pages/modules/settings/documentStatusPaths/Index.vue"),
    meta: {"role":["document_status_paths.list"]},
  },
  {
    path: "settings/document-status-paths/create",
    name: "document-status-paths.create",
    component: () =>
        import ("@/view/pages/modules/settings/documentStatusPaths/Form.vue"),
    meta: {"role":["document_status_paths.create"]},
  },
  {
    path: "settings/document-status-paths/edit/:id",
    name: "document-status-paths.edit",
    component: () =>
        import ("@/view/pages/modules/settings/documentStatusPaths/Form.vue"),
    meta: {"role":["document_status_paths.update"]},
  },
  {
    path: "settings/status-mapping",
    name: "status-mapping.index",
    component: () =>
        import ("@/view/pages/modules/settings/statusMapping/Index.vue"),
    meta: {"role":["status_mapping.list"]},
  },
  {
    path: "settings/status-mapping/edit/:id",
    name: "status-mapping.edit",
    component: () =>
        import ("@/view/pages/modules/settings/statusMapping/Form.vue"),
    meta: {"role":["status_mapping.update"]},
  },
  {
    path: "settings/connected-printers",
    name: "connected-printers.index",
    component: () =>
        import ("@/view/pages/modules/settings/connectedPrinters/Index.vue"),
    meta: {"role":["connected_printers.list"]},
  },
  {
    path: "settings/connected-printers/create",
    name: "connected-printers.create",
    component: () =>
        import ("@/view/pages/modules/settings/connectedPrinters/Form.vue"),
    meta: {"role":["connected_printers.create"]},
  },
  {
    path: "settings/connected-printers/edit/:id",
    name: "connected-printers.edit",
    component: () =>
        import ("@/view/pages/modules/settings/connectedPrinters/Form.vue"),
    meta: {"role":["connected_printers.update"]},
  },
  {
    path: "/settings/fabric-problems",
    name: "fabric-problems.index",
    component: () =>
        import ("@/view/pages/modules/settings/fabricProblems/Index.vue"),
    meta: {"role":["fabric_problems.list"]},
  },
];