export default [
  {
    path: "accounting/financial-years",
    name: "financial-years.index",
    component: () =>
        import ("@/view/pages/modules/accounting/financialYears/Index.vue"),
    meta: {"role":["financial_years.list"]},
  },
  {
    path: "accounting/financial-years/create",
    name: "financial-years.create",
    component: () =>
        import ("@/view/pages/modules/accounting/financialYears/Form.vue"),
    meta: {"role":["financial_years.create"]},
  },
  {
    path: "accounting/financial-years/edit/:id",
    name: "financial-years.edit",
    component: () =>
        import ("@/view/pages/modules/accounting/financialYears/Form.vue"),
    meta: {"role":["financial_years.update"]},
  },
  {
    path: "accounting/financial-years/view/:id",
    name: "financial-years.view",
    component: () =>
        import ("@/view/pages/modules/accounting/financialYears/View.vue"),
    meta: {"role":["financial_years.list"]},
  },
  {
    path: "accounting/closed-periods",
    name: "closed-periods.index",
    component: () =>
        import ("@/view/pages/modules/accounting/closedPeriods/Index.vue"),
    meta: {"role":["closed_periods.list"]},
  },
  {
    path: "accounting/closed-periods/create",
    name: "closed-periods.create",
    component: () =>
        import ("@/view/pages/modules/accounting/closedPeriods/Form.vue"),
    meta: {"role":["closed_periods.create"]},
  },
  {
    path: "accounting/closed-periods/edit/:id",
    name: "closed-periods.edit",
    component: () =>
        import ("@/view/pages/modules/accounting/closedPeriods/Form.vue"),
    meta: {"role":["closed_periods.update"]},
  },
  {
    path: "accounting/closed-periods/view/:id",
    name: "closed-periods.view",
    component: () =>
        import ("@/view/pages/modules/accounting/closedPeriods/View.vue"),
    meta: {"role":["closed_periods.list"]},
  },
  {
    path: "accounting/chart-accounts",
    name: "chart-accounts.index",
    component: () =>
        import ("@/view/pages/modules/accounting/chartAccounts/Index.vue"),
    meta: {"role":["chart_accounts.list"]},
  },
  {
    path: "accounting/chart-accounts/create/:parent_id?",
    name: "chart-accounts.create",
    component: () =>
        import ("@/view/pages/modules/accounting/chartAccounts/Form.vue"),
    meta: {"role":["chart_accounts.create"]},
  },
  {
    path: "accounting/chart-accounts/edit/:id",
    name: "chart-accounts.edit",
    component: () =>
        import ("@/view/pages/modules/accounting/chartAccounts/Form.vue"),
    meta: {"role":["chart_accounts.update"]},
  },
  {
    path: "accounting/chart-account-setting",
    name: "chart-accounts.setting.index",
    component: () =>
        import ("@/view/pages/modules/accounting/chartAccounts/Setting.vue"),
    meta: {"role":["chart_accounts_settings.update"]},
  },
  {
    path: "accounting/journal-entries",
    name: "journal-entries.index",
    component: () =>
        import ("@/view/pages/modules/accounting/journalEntries/Index.vue"),
    meta: {"role":["jornal_entries.list"]},
  },
  {
    path: "accounting/journal-entries/create",
    name: "journal-entries.create",
    component: () =>
        import ("@/view/pages/modules/accounting/journalEntries/Form.vue"),
  },
  {
    path: "accounting/journal-entries/edit/:id",
    name: "journal-entries.edit",
    component: () =>
        import ("@/view/pages/modules/accounting/journalEntries/Form.vue"),
  },
  {
    path: "accounting/assets-info",
    name: "assets-info.index",
    component: () =>
        import ("@/view/pages/modules/accounting/assetsInfo/Index.vue"),
    meta: {"role":["assets_info.list"]},
  },
  {
    path: "accounting/assets-info/create",
    name: "assets-info.create",
    component: () =>
        import ("@/view/pages/modules/accounting/assetsInfo/Form.vue"),
    meta: {"role":["assets_info.create"]},
  },
  {
    path: "accounting/assets-info/edit/:id",
    name: "assets-info.edit",
    component: () =>
        import ("@/view/pages/modules/accounting/assetsInfo/Form.vue"),
    meta: {"role":["assets_info.update"]},
  },
  {
    path: "accounting/accounts-routing",
    name: "accounts-routing.create",
    component: () =>
        import ("@/view/pages/modules/accounting/accountsRouting/Form.vue"),
    meta: {"role":["accounts_routing.update"]},
  },
  {
    path: "accounting/cost-centers",
    name: "cost-centers.index",
    component: () =>
        import ("@/view/pages/modules/accounting/costCenters/Index.vue"),
    meta: {"role":["cost_centers.list"]},
  },
  {
    path: "accounting/cost-centers/create/:parent_id?",
    name: "cost-centers.create",
    component: () =>
        import ("@/view/pages/modules/accounting/costCenters/Form.vue"),
    meta: {"role":["cost_centers.create"]},
  },
  {
    path: "accounting/cost-centers/edit/:id",
    name: "cost-centers.edit",
    component: () =>
        import ("@/view/pages/modules/accounting/costCenters/Form.vue"),
    meta: {"role":["cost_centers.update"]},
  },
  {
    path: "accounting/cost-centers/transactions",
    name: "cost-centers.transactions",
    component: () =>
        import ("@/view/pages/modules/accounting/costCenters/Transactions.vue"),
    meta: {"role":["cost_centers.all_transactions"]},
  },
  {
    path: "accounting/accounts-without-cost-center",
    name: "accounts-without-cost-center.list",
    component: () =>
        import ("@/view/pages/modules/accounting/accountsWithoutCostCenter/Index.vue"),
    meta: {"role":["accounts_without_cost_center.list"]},
  },
  {
    path: "accounting/accounts-without-cost-center/:id",
    name: "accounts-without-cost-center.create",
    component: () =>
        import ("@/view/pages/modules/accounting/accountsWithoutCostCenter/Form.vue"),
    meta: {"role":["accounts_without_cost_center.create","accounts_without_cost_center.assign_cost_center"]},
  },
  {
    path: "accounting/accounts-with-cost-center",
    name: "accounts-with-cost-center.list",
    component: () =>
        import ("@/view/pages/modules/accounting/accountsWithCostCenter/Index.vue"),
    meta: {"role":["accounts_with_cost_center.list"]},
  },
  {
    path: "accounting/cost-center-transactions",
    name: "cost-center-transactions.list",
    component: () =>
        import ("@/view/pages/modules/accounting/costCenterTransactions/Index.vue"),
    meta: {"role":["cost_center_transactions.list"]},
  },
  {
    path: "accounting/default-journalist-setting",
    name: "default-journalist-setting.list",
    component: () =>
        import ("@/view/pages/modules/accounting/defaultJournalistSetting/Form.vue"),
    meta: {"role":["default_journalist_setting.list"]},
  },
  {
    path: "/accounting/secondary-accounts-tree",
    name: "secondary-accounts-tree.index",
    component: () =>
        import ("@/view/pages/modules/accounting/secondaryAccountsTree/Form.vue"),
    meta: {"role":["secondary_accounts.list"]},
  },
  {
    path: "/accounting/groups-accounts",
    name: "groups-accounts.index",
    component: () =>
        import ("@/view/pages/modules/accounting/groupsAccounts/Index.vue"),
    meta: {"role":["groups_accounts.list"]},
  },
  {
    path: "/accounting/groups-accounts/create",
    name: "groups-accounts.create",
    component: () =>
        import ("@/view/pages/modules/accounting/groupsAccounts/Form.vue"),
    meta: {"role":["groups_accounts.create"]},
  },
  {
    path: "/accounting/groups-accounts/edit/:id",
    name: "groups-accounts.edit",
    component: () =>
        import ("@/view/pages/modules/accounting/groupsAccounts/Form.vue"),
    meta: {"role":["groups_accounts.update"]},
  },
  {
    path: "/accounting/chart-accounts-list/:parent_id?",
    name: "chart-accounts-list.index",
    component: () =>
        import ("@/view/pages/modules/accounting/chartAccountsList/Index.vue"),
    meta: {"role":["chart_accounts.list"]},
  },
  {
    path: "/accounting/journals-entries-account/:account_id",
    name: "journals-entries-account.index",
    component: () =>
        import ("@/view/pages/modules/accounting/chartAccounts/journalEntriesAccount.vue"),
    meta: {"role":["chart_accounts.journals_entries_account"]},
  },
  {
    path: "accounting/financial-list",
    name: "financial-list.index",
    component: () =>
        import ("@/view/pages/modules/accounting/financialList/Index.vue"),
    meta: {"role":["financial_list.list"]},
  },
  {
    path: "accounting/financial-list/create",
    name: "financial-list.create",
    component: () =>
        import ("@/view/pages/modules/accounting/financialList/Form.vue"),
    meta: {"role":["financial_list.create"]},
  },
  {
    path: "accounting/financial-list/edit/:id?",
    name: "financial-list.edit",
    component: () =>
        import ("@/view/pages/modules/accounting/financialList/Form.vue"),
    meta: {"role":["financial_list.update"]},
  },
  {
    path: "accounting/general-accounting-configurations",
    name: "general-accounting-configurations.edit",
    component: () =>
        import ("@/view/pages/modules/accounting/generalSettings/Form.vue"),
    meta: {"role":["general_setting_accounting.update"]},
  },
  {
    path: "accounting/closing-entries-settings",
    name: "closing-entries-settings.edit",
    component: () =>
        import ("@/view/pages/modules/accounting/closingEntriesSettings/Form.vue"),
    meta: {"role":["closing_entries_settings.update"]},
  },
  {
    path: "accounting/open-balance-financial-years/:financial_year_id",
    name: "open-balance-financial-years.edit",
    component: () =>
        import ("@/view/pages/modules/accounting/financialYears/OpenBalance.vue"),
    // meta: {"role":["open-balance-financial-years.update"]},
  },
  {
    path: "accounting/financial_list_details/:slug",
    name: "financial_list_details.list",
    component: () =>
        import ("@/view/pages/modules/accounting/financialList/sheets/Details.vue"),
    // meta: {"role":["open-balance-financial-years.update"]},
  },
  {
    path: "accounting/import-open-balance-financial-year",
    name: "import-open-balance-financial-year.list",
    component: () =>
        import ("@/view/pages/modules/accounting/importOpenBalance/Index.vue"),
    meta: {"role":["chart_accounts.import_open_balances"]},
  },
  {
    path: "accounting/financial-list/entries-details/:slug",
    name: "financial-list-entries-details.list",
    component: () =>
        import ("@/view/pages/modules/accounting/financialList/sheets/EntriesDetails.vue"),
    meta: {"role":["financial_list.entries_details"]},
  },
    /****************START equity-setting*********************/
  {
    path: "accounting/equity-setting",
    name: "equity-setting.index",
    component: () =>
        import ("@/view/pages/modules/accounting/equitySetting/Index.vue"),
    meta: {"role":["equity_setting.list"]},
  },
  {
    path: "accounting/equity-setting/create",
    name: "equity-setting.create",
    component: () =>
        import ("@/view/pages/modules/accounting/equitySetting/Form.vue"),
    meta: {"role":["equity_setting.create"]},
  },
  {
    path: "accounting/equity-setting/edit/:id",
    name: "equity-setting.create",
    component: () =>
        import ("@/view/pages/modules/accounting/equitySetting/Form.vue"),
    meta: {"role":["equity_setting.create"]},
  },
  /****************END equity-setting*********************/
];