export default [
  {
    path: "cheques-cycle/cheque-book",
    name: "cheque-book.index",
    component: () =>
        import ("@/view/pages/modules/chequesCycle/chequesBooks/Index.vue"),
    meta: {"role":["book_cheques.list"]},
  },
  {
    path: "cheques-cycle/cheque-book/create",
    name: "cheque-book.create",
    component: () =>
        import ("@/view/pages/modules/chequesCycle/chequesBooks/Form.vue"),
    meta: {"role":["book_cheques.create"]},
  },
  {
    path: "cheques-cycle/cheque-book/edit/:id",
    name: "cheque-book.edit",
    component: () =>
        import ("@/view/pages/modules/chequesCycle/chequesBooks/Form.vue"),
    meta: {"role":["book_cheques.update"]},
  },
  {
    path: "cheques-cycle/cheque-book/view/:id",
    name: "cheque-book.view",
    component: () =>
        import ("@/view/pages/modules/chequesCycle/chequesBooks/View.vue"),
    meta: {"role":["book_cheques.list"]},
  },
  {
    path: "cheques-cycle/payable-cheques",
    name: "payable-cheques.index",
    component: () =>
        import ("@/view/pages/modules/chequesCycle/payableCheques/Index.vue"),
    meta: {"role":["payable_cheques.list"]},
  },
  {
    path: "cheques-cycle/payable-cheques/create",
    name: "payable-cheques.create",
    component: () =>
        import ("@/view/pages/modules/chequesCycle/payableCheques/Form.vue"),
    meta: {"role":["payable_cheques.create"]},
  },
  {
    path: "cheques-cycle/payable-cheques/edit/:id",
    name: "payable-cheques.edit",
    component: () =>
        import ("@/view/pages/modules/chequesCycle/payableCheques/Form.vue"),
    meta: {"role":["payable_cheques.update"]},
  },
  {
    path: "cheques-cycle/payable-cheques/view/:id",
    name: "payable-cheques.view",
    component: () =>
        import ("@/view/pages/modules/chequesCycle/payableCheques/View.vue"),
    meta: {"role":["payable_cheques.list"]},
  },
  {
    path: "cheques-cycle/receivable-cheques",
    name: "receivable-cheques.index",
    component: () =>
        import ("@/view/pages/modules/chequesCycle/receivableCheques/Index.vue"),
    meta: {"role":["receivable_cheques.list"]},
  },
  {
    path: "cheques-cycle/receivable-cheques/create",
    name: "receivable-cheques.create",
    component: () =>
        import ("@/view/pages/modules/chequesCycle/receivableCheques/Form.vue"),
    meta: {"role":["receivable_cheques.create"]},
  },
  {
    path: "cheques-cycle/receivable-cheques/edit/:id",
    name: "receivable-cheques.edit",
    component: () =>
        import ("@/view/pages/modules/chequesCycle/receivableCheques/Form.vue"),
    meta: {"role":["receivable_cheques.update"]},
  },
  {
    path: "cheques-cycle/receivable-cheques/view/:id",
    name: "receivable-cheques.view",
    component: () =>
        import ("@/view/pages/modules/chequesCycle/receivableCheques/View.vue"),
    meta: {"role":["receivable_cheques.list"]},
  },
];