export default [
  {
    path: "employees/employees",
    name: "employees.index",
    component: () =>
        import ("@/view/pages/modules/employees/employees/Index.vue"),
    meta: {"role":["employees.list"]},
  },
  {
    path: "employees/employees/create",
    name: "employees.create",
    component: () =>
        import ("@/view/pages/modules/employees/employees/Form.vue"),
    meta: {"role":["employees.create"]},
  },
  {
    path: "employees/employees/edit/:id",
    name: "employees.edit",
    component: () =>
        import ("@/view/pages/modules/employees/employees/Form.vue"),
    meta: {"role":["employees.update"]},
  },
  {
    path: "employees/sales-man-commission",
    name: "sales-man-commission.create",
    component: () =>
        import ("@/view/pages/modules/employees/salesManCommissions/Index.vue"),
  },
  {
    path: "employees/departments",
    name: "departments.index",
    component: () =>
        import ("@/view/pages/modules/employees/departments/Index.vue"),
    meta: {"role":["departments.list"]},
  },
];