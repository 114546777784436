export default [
  {
    path: "loyalty/clients-loyalty",
    name: "clients-loyalty.list",
    component: () =>
        import ("@/view/pages/modules/loyalty/clientsLoyalty/Form.vue"),
    meta: {"role":["clients_loyalty.update"]},
  },
  {
    path: "loyalty/loyalty-rules",
    name: "loyalty-rules.list",
    component: () =>
        import ("@/view/pages/modules/loyalty/loyaltyRules/Index.vue"),
    meta: {"role":["loyalty_rules.list"]},
  },
  {
    path: "loyalty/loyalty-rules/create",
    name: "loyalty-rules.create",
    component: () =>
        import ("@/view/pages/modules/loyalty/loyaltyRules/Form.vue"),
    meta: {"role":["loyalty_rules.create"]},
  },
  {
    path: "loyalty/loyalty-rules/edit/:id",
    name: "loyalty-rules.edit",
    component: () =>
        import ("@/view/pages/modules/loyalty/loyaltyRules/Form.vue"),
    meta: {"role":["loyalty_rules.update"]},
  },
];