export default [
  {
    path: "pos/laundry-subscriptions",
    name: "laundry-subscriptions.index",
    component: () =>
        import ("@/view/pages/modules/pos/laundrySubscriptions/Index.vue"),
    meta: {"role":["laundry_subscriptions.list"]},
  },
  {
    path: "pos/laundry-subscriptions/create",
    name: "laundry-subscriptions.create",
    component: () =>
        import ("@/view/pages/modules/pos/laundrySubscriptions/Form.vue"),
    meta: {"role":["laundry_subscriptions.create"]},
  },
  {
    path: "pos/laundry-subscriptions/edit/:id",
    name: "laundry-subscriptions.edit",
    component: () =>
        import ("@/view/pages/modules/pos/laundrySubscriptions/Form.vue"),
    meta: {"role":["laundry_subscriptions.update"]},
  },
  {
    path: "/pos/laundry-subscriptions/view/:id",
    name: "laundry-subscriptions.view",
    component: () =>
        import ("@/view/pages/modules/pos/laundrySubscriptions/View.vue"),
    meta: {"role":["laundry_subscriptions.list"]},
  },
  {
    path: "/pos/laundry-subscriptions/add-subscriber/:id",
    name: "laundry-subscriptions.add-subscriber",
    component: () =>
        import ("@/view/pages/modules/pos/laundrySubscriptions/AddSubscriber.vue"),
    meta: {"role":["laundry_subscriptions.customers"]},
  },
  {
    path: "pos/pos-devices",
    name: "pos-devices.index",
    component: () =>
        import ("@/view/pages/modules/pos/posDevices/Index.vue"),
    meta: {"role":["pos_devices.list"]},
  },
  {
    path: "pos/pos-devices/create",
    name: "pos-devices.create",
    component: () =>
        import ("@/view/pages/modules/pos/posDevices/Form.vue"),
    meta: {"role":["pos_devices.create"]},
  },
  {
    path: "pos/pos-devices/edit/:id",
    name: "pos-devices.edit",
    component: () =>
        import ("@/view/pages/modules/pos/posDevices/Form.vue"),
    meta: {"role":["pos_devices.update"]},
  },
  {
    path: "pos/pos-stores",
    name: "pos-stores.index",
    component: () =>
        import ("@/view/pages/modules/pos/posStores/Index.vue"),
    meta: {"role":["pos_stores.list"]},
  },
  {
    path: "pos/pos-stores/create",
    name: "pos-stores.create",
    component: () =>
        import ("@/view/pages/modules/pos/posStores/Form.vue"),
    meta: {"role":["pos_stores.create"]},
  },
  {
    path: "pos/pos-stores/edit/:id",
    name: "pos-stores.edit",
    component: () =>
        import ("@/view/pages/modules/pos/posStores/Form.vue"),
    meta: {"role":["pos_stores.update"]},
  },
  {
    path: "pos/pos-shifts",
    name: "pos-shifts.index",
    component: () =>
        import ("@/view/pages/modules/pos/posShifts/Index.vue"),
    meta: {"role":["pos_shifts.list"]},
  },
  {
    path: "pos/pos-shifts/create",
    name: "pos-shifts.create",
    component: () =>
        import ("@/view/pages/modules/pos/posShifts/Form.vue"),
    meta: {"role":["pos_shifts.create"]},
  },
  {
    path: "pos/pos-shifts/edit/:id",
    name: "pos-shifts.edit",
    component: () =>
        import ("@/view/pages/modules/pos/posShifts/Form.vue"),
    meta: {"role":["pos_shifts.update"]},
  },
  {
    path: "pos/pos-settings",
    name: "pos-settings.index",
    component: () =>
        import ("@/view/pages/modules/pos/posSettings/Form.vue"),
    meta: {"role":["pos_settings.update"]},
  },
  {
    path: "pos/pos-session",
    name: "pos-session.index",
    component: () =>
        import ("@/view/pages/modules/pos/posSessions/Index.vue"),
    meta: {"role":["pos_session.list"]},
  },
  {
    path: "pos/pos-session/create",
    name: "pos-session.create",
    component: () =>
        import ("@/view/pages/modules/pos/posSessions/Form.vue"),
    meta: {"role":["pos_session.create"]},
  },
  {
    path: "pos/pos-session/view/:id/:current_tab?",
    name: "pos-session.view",
    component: () =>
        import ("@/view/pages/modules/pos/posSessions/View.vue"),
    meta: {"role":["pos_session.details"]},
  },
  {
    path: "pos/cash-closing/:id",
    name: "pos-session.cash-closing",
    component: () =>
        import ("@/view/pages/modules/pos/posSessions/cashClosing.vue"),
    meta: {"role":["pos_session.cash_closing"]},
  },
  {
    path: "pos/pos-session/invoice/view/:id",
    name: "pos-session-invoice.view",
    component: () =>
        import ("@/view/pages/modules/pos/posSessions/invoice/View.vue"),
    meta: {"role":["pos_session.list"]},
  },
  {
    path: "pos/pos-session/refund/view/:id",
    name: "pos-session-refund.view",
    component: () =>
        import ("@/view/pages/modules/pos/posSessions/refund/View.vue"),
    meta: {"role":["pos_refund.view"]},
  },
  {
    path: "pos/cash-movement/:id",
    name: "cash-movement.list",
    component: () =>
        import ("@/view/pages/modules/pos/cashMovement/Index.vue"),
    meta: {"role":["cash_movement.list"]},
  },
];