<template>
  <div>
    <b-dropdown right :text="action_name" class="mr-2 ml-2 h-100">

      <button class="dropdown-item" @click="startDownload('excel')"  v-if="!checkingForIgnoreActions('excel')">
        <i class="la la-file-excel"></i>{{ $t('excel') }}
      </button>
      <button class="dropdown-item" @click="startDownload('print')"  v-if="!checkingForIgnoreActions('print')">
        <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
      </button>
      <button class="dropdown-item" @click="startDownload('pdf')"  v-if="!checkingForIgnoreActions('pdf')">
        <span><i class="fa fa-file-pdf"></i> <span>{{ $t('export_pdf') }}</span></span>
      </button>


      <button @click="showModal" class="dropdown-item">
        <span><span>{{ $t('custom_export') }}</span></span>
      </button>

    </b-dropdown>
    <b-modal ref="modalExport" hide-footer :title="$t('custom_export')">
      <div class="row">
        <div class="col-md-12 mb-7">
          <b-form-checkbox v-model="select_all" :key="'select_all'">{{ $t('select_all') }}</b-form-checkbox>
        </div>
        <div class="col-md-6 mb-3" v-for="(row, index) in fields" :key="index">
          <b-form-checkbox v-model="customFields" @change="selectValue" :value="row" :key="row">{{ index }}</b-form-checkbox>
        </div>
      </div>


      <div class="card-footer pl-0 pr-0 pb-0">
        <div class="row">
          <div class="col-lg-12">

            <button class="btn btn-primary mt-2 btn-sm" @click="startDownload('excel')" v-if="!checkingForIgnoreActions('excel')">
              <i class="fa fa-file-excel"></i>{{ $t('excel') }}
            </button>

            <button @click="startDownload('print')" v-if="!checkingForIgnoreActions('print')" class="btn btn-primary mt-2 mr-1 btn-sm">
              <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
            </button>
            <button @click="startDownload('pdf')" v-if="!checkingForIgnoreActions('pdf')" class="btn btn-primary mt-2 mr-1 btn-sm">
              <span><i class="fa fa-file-pdf"></i> <span>{{ $t('export_pdf') }}</span></span>
            </button>

            <b-button class="mt-2 mr-1" variant="secondary" @click="hideModal">{{ $t('close') }}</b-button>

          </div>
        </div>
      </div>
    </b-modal>


    <div class="export-container">
      <div class="section-for-export" :id="id_name">
        <table class="table table-bordered">
          <thead>
          <tr>
            <th v-for="(_th, index) in customFields" :key="index">
              {{ selectedFields[_th] }}
            </th>

          </tr>
          </thead>
          <tbody>

          <template v-if="data">
            <tr v-for="(row, index) in data" :key="index">
              <template v-for="(td, index_2) in customFields">
                <td :key="index +'_'+ index_2">{{ row[td] ? row[td] : '' }}</td>
              </template>

            </tr>
          </template>


          </tbody>
        </table>
      </div>
    </div>
  </div>

</template>

<script>
import ApiService from "@/core/services/api.service";
import TableToExcel from "@linways/table-to-excel";
import {jsPDF} from "jspdf";
import jsPdfExport from "@/core/config/jsPdfExport";

export default {
  name: "CustomExportData",
  props: ['route', 'fields', 'filters', 'dataList', 'fileName', 'actionName', 'idName', 'ignoreActions', 'subHeader'],
  data() {
    return {
      selectedFields: {},
      customFields: [],
      select_all: true,
      data: [],
      action_name: this.$t('export'),
      id_name: 'sectionForExport',
      sub_header: {},
    }
  },
  watch: {
    select_all: function (val) {
      if (val) {
        this.selectedFields = {};
        this.customFields = [];
        let _keys = Object.keys(this.fields);
        _keys.forEach((index) => {
          let _val = this.fields[index];
          this.selectedFields[_val] = index;
          this.customFields.push(_val);
        });
        // Object.assign(this.customFields, this.fields);
      } else {
        this.selectedFields = {};
        this.customFields = [];
      }


    },
    dataList: function (val) {
      this.data = val? val : [];
    },
  },
  methods: {
    printData(type) {

      // let divToPrint = document.getElementById('sectionForExport');
      // let divTitleToPrint = this.fileName;
      // let newWin = window.open("");
      // newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divTitleToPrint + divToPrint.outerHTML);
      // setTimeout(() => {
      //     newWin.print();
      //     newWin.close();
      // }, 100)

      this.exportPDF(this.id_name , type);
    },
    exportPDF(_id , type) {
      let that = this;
      // let pdf = jsPDF("p", "px", 'a4');
      //
      // const AmiriRegular = pdf.loadFile("https://rawcdn.githack.com/MrRio/jsPDF/cbc85b4bb66d5c1a2d50d199e8bf33836f2f12d7/test/reference/Amiri-Regular.ttf", true);
      // pdf.addFileToVFS("Amiri-Regular.ttf", AmiriRegular);
      //
      // pdf.addFont("Amiri-Regular.ttf", "Amiri", "normal");
      let pdf = jsPdfExport("p", "px", 'a4');
      pdf.html(document.getElementById(_id), {
        html2canvas: {
          scale: 0.5,
        },
        callback: function (pdf) {
          if (type == 'pdf') {
            pdf.save(that.fileName);
          } else {
            pdf.autoPrint();
            pdf.output('dataurlnewwindow');
          }
        //
        //   // var iframe = document.createElement('iframe');
        //   // iframe.setAttribute('style', 'position:absolute;top:0;right:0;height:100%; width:600px');
        //   // document.body.appendChild(iframe);
        //   // iframe.src = pdf.output('datauristring');
        },

      });
    },

    convertTableToExcelReport() {

      TableToExcel.convert(document.getElementById(this.id_name), {
        name: this.fileName + '.xlsx',
        sheet: {
          name: 'Sheet 1'
        }
      });
    },

    checkingForIgnoreActions(_action){
      if (this.ignoreActions){
        if (this.ignoreActions.length > 0){
          return this.ignoreActions.includes(_action)
        }
      }
      return false;
    },

    /**
     * function to get data without pagination
     * to export data
     * @returns {Promise<*>}
     */

    async fetchData(type) {
      await ApiService.query(this.route, {is_export: 1, ...this.filters}).then((response) => {
        this.data = response.data.data;
        if (type == 'excel')
          this.data.unshift(this.sub_header);
      });
    },


    startDownload(type) {
      if (this.route) {
        let promise = this.fetchData(type);
        Promise.all([promise]).then(() => {
          if (type == 'print' || type == 'pdf') {
            this.printData(type);
          } else if (type == 'excel') {
            this.convertTableToExcelReport();
          }
        });
      } else {
        this.data = this.dataList? this.dataList : [];
        if (type == 'print' || type == 'pdf') {
          this.printData(type);
        } else if (type == 'excel') {
          this.convertTableToExcelReport();
        }
      }
    },
    finishDownload() {
    },
    showModal() {
      this.$refs['modalExport'].show()
    },
    hideModal() {
      this.$refs['modalExport'].hide();
    },
    toggleModal() {
      this.$refs['modalExport'].toggle('#toggle-btn');
    },
    selectValue() {

      this.selectedFields = {};
      let _custom_fields = {};

      let _keys = Object.keys(this.fields);
      _keys.forEach((index) => {
        let _val = this.fields[index];
        _custom_fields[_val] = index;
      });

      this.customFields.forEach((row) => {
        let _val = _custom_fields[row];
        this.selectedFields[row] = _val;
      });


    },
    selectAll() {
      this.select_all = !this.select_all;
    }
  },
  created() {
    this.selectedFields = {};
    this.sub_header = this.subHeader ? this.subHeader : {};
    this.customFields = [];
    if (this.actionName)
      this.action_name = this.actionName;

    if (this.idName)
      this.id_name = this.idName;

    let _keys = Object.keys(this.fields);
    _keys.forEach((index) => {
      let _val = this.fields[index];
      this.selectedFields[_val] = index;

      this.customFields.push(_val);
    });

  }
}
</script>

<style>
.export-container {
  width: 0.0001px !important;
  height: 0.0001px !important;
  overflow: hidden !important;
}
.section-for-export{
  font-family: "Amiri";
  width: 235mm;
  padding: 30px 15px;
}
.html2pdf__overlay{
  opacity: 0 !important;
  z-index: -1000;
}
</style>